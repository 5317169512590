import React from 'react';
import './projectRyba.modules.css';
import threeMobiles from './images/threeMobiles.png';
import reactPic from './images/reactPic.png';

import twoMobiles from './../sectionLatestWork/images/twoMobiles.png';
import laptop from './../sectionLatestWork/images/laptop.png';

import aa from './images/aa.png';
import bb from './images/bb.png';
import cc from './images/cc.png';
import dd from './images/dd.png';
import style from './images/style.png';
import w1 from './images/w1.png';
import w2 from './images/w2.png';
import w3 from './images/w3.png';
import home from './images/home.png';
import homeM from './images/homeM.png';
import bio from './images/bio.png';
import bioM from './images/bioM.png';

import tabPic1 from './../sectionLatestWork/images/tabPic1.png';
import leftAero from './images/leftAero.png';
import SectionTopBar from '../sectionTopBar/sectionTopBar';
import SectionFooter from '../sectionFooter/sectionFooter';

export default () => {
    return (
        <div className='mainClassNeuroMobileWebsite'>
            <SectionTopBar styleColor="#F6F1EC" />
            <div className='backAeroContainerRyba'>
                <a className='backAeroContainerSub' href="javascript:history.back()"><img src={leftAero} className='leftAero' alt='Ali' /></a>
            </div>

            <div className='mainRybaContainer'>
                <h3 className='neuroMobileAppHeading'>Project Ryba - Website</h3>
                <p className='neuroMobileAppParagraph'>An app with world’s best features and exams for the mental health.</p>

                <img src={threeMobiles} className='threeMobiles' alt='Ali' />

            </div>

            <div className='neuroMobileSectionOverview'>
                <div className='neuroMobileSectionOverviewDivOne'>
                    <h4 className='neuroMobileSectionOverviewHeading'>OVERVIEW</h4>
                    <br></br>
                    <p className='neuroMobileSectionOverviewParagraphs'>This a tribute website for the famous personality of Czech Republic in Europe. He took so many initiatives for the country. Also he did an examplary work in the domain of music and literature. He wrote many books. </p>

                    <p className='neuroMobileSectionOverviewParagraphs'>Purpose of this website is to build platform for the people who want to enjoy the music of Jakub and want to read more books written by Jakub.</p>
                    <br></br>
                    <h5 className='neuroMobileSectionOverviewHeading'>CLIENT</h5>
                    <img src={reactPic} className='rybaLogoNeuroMobile' alt='React Logo' />

                </div>
                <div className='neuroMobileSectionOverviewDivTwo'>
                    <h4 className='neuroMobileSectionOverviewHeading'>ROLE & DURATION</h4>
                    <br></br>
                    <h5 className='neuroMobileSectionUsersProfilesParagraphCustom' >UX Designer </h5>
                    <p className='neuroMobileSectionOverviewCustomParagraph'>Visual Design, Interaction, Prototyping.</p>
                    <p className='neuroMobileSectionOverviewParagraphs'>Jun 2020 - 2 Weeks</p>
                    <br></br>
                    <h4 className='neuroMobileSectionOverviewHeadingRyba'>PROBLEM / CHALLENGE</h4>
                    <p className='neuroMobileSectionOverviewCustomParagraph'>When it comes to get the resources of this specific persolity like music and books there is not any authentic source of that resources.</p>
                </div>
            </div>


            <div className='neuroMobileSectionInsights'>

                <div className='neuroMobileSectionInsightsMainDiv'>
                    <h3 className='rybaSectionInsightsMainDivHeading'>Design Principles</h3>
                    <p className='rybaSectionInsightsMainDivParagraph'>Defining the top 5-6 design principles for theNeighbore platform</p>
                </div>
                <div className='neuroMobileSectionInsightsMainContainer'>
                    <div className='rybaSectionInsightsDivsContainer'>
                        <div className='rybaSectiondesignPrincipalsdiv'>
                            <div className='rybaSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Simple </p>
                            </div>
                            <div className='rybaSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Human </p>
                            </div>


                            <div className='rybaSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Activates Gratitude </p>
                            </div>
                        </div>
                        <div className='rybaSectiondesignPrincipalsdiv'>
                            <div className='rybaSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Social </p>
                            </div>


                            <div className='rybaSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Instant </p>
                            </div>
                            <div className='rybaSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Accessible </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className='rybaSectionScope'>
                <div className='rybaSectionScopeMainDiv'>
                    <h3 className='rybaSectionInsightsMainDivHeading'>Scope of Work</h3>
                </div>

                <div className='rybaSectionScopeMainDivImg'>
                    <img src={aa} className='rybaSectionScopeImgClass' alt='React Logo' />
                    <img src={bb} className='rybaSectionScopeImgClass' alt='React Logo' />
                    <img src={cc} className='rybaSectionScopeImgClass' alt='React Logo' />
                    <img src={dd} className='rybaSectionScopeImgClass' alt='React Logo' />
                </div>

            </div>


            <div className='rybaSectionScope'>
                <div className='rybaSectionScopeMainDiv'>
                    <h3 className='rybaSectionInsightsMainDivHeading'>Style Guide</h3>

                    <p className='neuroMobileSectionConceptualizationParagraph'>We followed the base guidelines of Material Design to create styles for Neighboure.</p>

                    <img src={style} className='researchPicNeuroMobile' alt='React Logo' />
                    <br></br><br></br><br></br>
                    <h3 className='rybaSectionInsightsMainDivHeading'>Wireframes</h3>

                    <p className='neuroMobileSectionConceptualizationParagraph'>Designed lo-fidelity wireframes to get to know better about the features of the app.</p>
                </div>
                <div>
                    <img src={w1} className='rybaSectionWireFramesImg' alt='React Logo' />
                    <img src={w2} className='rybaSectionWireFramesImg' alt='React Logo' />
                    <img src={w3} className='rybaSectionWireFramesImg' alt='React Logo' />
                </div>
                <br></br><br></br><br></br><br></br>
            </div>

            <div className='rybaSectionScope'>
                <div className='rybaSectionScopeMainDiv'>

                    <h3 className='rybaSectionInsightsMainDivHeading'>High-Fidelity Designs</h3>

                    <p className='rybaSectionHighConceptualizationParagraph'>Website mockups and prototype designed for the product.</p>
                    <br></br><br></br><br></br><br></br>
                    <h4 className='rybaSectionHomeMidHeading'>Home Page</h4>
                    <p className='neuroMobileSectionConceptualizationParagraph'>It contains all the latest news and basic information about Jakub Jan Ryba.</p>
                    <div className='rybaSectionHomeImgMain'>
                        <img src={home} className='rybaSectionHomeImg' alt='React Logo' />
                        <img src={homeM} className='rybaSectionHomeMobileImg' alt='React Logo' />
                    </div>

                    <br></br><br></br><br></br><br></br>
                    <h4 className='rybaSectionHomeMidHeading'>Biography</h4>
                    <p className='neuroMobileSectionConceptualizationParagraph'>It contains all the biography of the Jakub. It tells how he prove himself in field of literature  and music.</p>
                    <div className='rybaSectionHomeImgMain'>
                        <img src={bio} className='rybaSectionBioImg' alt='React Logo' />
                        <img src={bioM} className='rybaSectionBioMobileImg' alt='React Logo' />
                    </div>
                    <br></br><br></br><br></br>
                </div>
                <a href='http://www.projectryba.com' target='_blank' className='viewFullPriject'>View Full Project</a>
                <br></br><br></br><br></br>

            </div>


            <div className='neuroMobileSectionUserProfiles'>
                <div className='neuroMobileSectionInsightsMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>More Projects</h3>
                </div>
                <div className='neuroMobileSectionInsightsDivsContainer'>
                    <div>
                        <a href="/neighbourhood-mobile">
                            <div className='reactNeuroMobilesPicContainer'>
                                <img src={twoMobiles} className='Pic2NeuroMobile' alt='Ali' />
                            </div>
                        </a>
                        <h4 className='neuroMobileSectionMoreProjectsHeading'>Neighbourhood - Mobile App</h4>

                    </div>
                    <div>
                        <a href="/neuro-tab">
                            <div className='reactNeuroPicContainer'>
                                <img src={tabPic1} className='mobilePic1NeuroMobile' alt='Ali' />
                            </div>
                        </a>
                        <h4 className='neuroMobileSectionMoreProjectsHeading'>React Neuro - Tablet App</h4>
                    </div>
                    <div>
                        <a href="/project-ryba">
                            <div className='reactNeuroLaptopPicContainer'>
                                <img src={laptop} className='laptopPic1NeuroMobile' alt='Ali' />
                            </div>
                        </a>
                        <h4 className='neuroMobileSectionMoreProjectsHeading'>Project Ryba - Website</h4>
                    </div>
                </div>
            </div>
            <div className='neuroMobileSpacer'></div>
            {/* <SectionFooter /> */}
        </div>
    )
}
