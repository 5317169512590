import React from 'react';
import SectionTestNavBar from '../sectionTestNavBar/sectionTestNavBar';
import background from './images/background.jpg';
import './sectionTest.modules.css';

export default () => {
    return (
        <div>
            <SectionTestNavBar />
            <div className='mainClassTestContainer'>
                <h2>Publicially offered exclusively to media.</h2>
                <div className='mainClassTest'>
                    <div>
                        <p className='testParagraph'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages</p>
                        <p className='testParagraph'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centur</p>
                        <p className='testParagraph'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when</p>
                    </div>
                    <div className='registerBox'>
                        <h3 className='registerToday'> Register Today !</h3>
                        <p className='listContainer'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <ul className='listContainer'>
                            <li>Lorem Ipsum is simply dummy text of the.</li>
                            <li>Lorem Ipsum is simply dummy text of the.</li>
                            <li>Lorem Ipsum is simply dummy text of the .</li>
                        </ul>

                        <button className='registerButton'>Register</button>

                    </div>



                </div>

            </div>
        </div>
    )
}
