import React from 'react';
import './neighbourMobile.modules.css';
import threeMobiles from './images/threeMobiles.png';
import reactPic from './images/reactPic.PNG';
import researchPic from './images/researchPic.png';
import hopePic from './images/hope.png';
import mapPic from './images/map.png';
import competitiorPic from './images/competitiorPic.png';
import comp from './images/comp.png';
import resultsPic from './images/resultsPic.svg';
import sharingPic from './images/sharingPic.svg';
import securityPic from './images/securityPic.svg';
import personPic from './images/person.svg';
import scopePic from './images/scopePic.png';
import conceptualizationPic from './images/conceptualization.png';
import moodPic from './images/mood.png';
import brandPic from './images/brand.png';
import wirePic from './images/wire.png';
import twoMobiles from './../sectionLatestWork/images/twoMobiles.png';
import laptop from './../sectionLatestWork/images/laptop.png';
import tabPic1 from './../sectionLatestWork/images/tabPic1.png';
import leftAero from './images/leftAero.png';
// import video1 from "./videos/connection.mp4";
// import grant from "./videos/grant.mp4";
// import learn from "./videos/learn.mp4";
// import results from "./videos/results.mp4";
import video1 from "./videos/connection.mov";
import grant from "./videos/grant.mov";
import learn from "./videos/learn.mov";
import results from "./videos/results.mov";
import SectionTopBar from '../sectionTopBar/sectionTopBar';
import SectionFooter from '../sectionFooter/sectionFooter';

export default () => {
    return (
        <div className='mainClassNeuroMobileWebsite'>
            <SectionTopBar styleColor="rgba(78, 206, 114, 0.1)" />
            <div className='backAeroContainerNeighbourHoodMobile'>
                <a className='backAeroContainerSub' href="javascript:history.back()"><img src={leftAero} className='leftAero' alt='Ali' /></a>
            </div>

            <div className='mainNeuroMobileContainerNeighbourHoodMobile'>
                <h3 className='neuroMobileAppHeading'>Neighbourhood Mobile App</h3>
                <p className='neuroMobileAppParagraph'>An app to get interact with your community members in pandemic.</p>

                <img src={threeMobiles} className='threeMobiles' alt='Ali' />

            </div>

            <div className='neuroMobileSectionOverview'>
                <div className='neuroMobileSectionOverviewDivOne'>
                    <h4 className='neuroMobileSectionOverviewHeading'>OVERVIEW</h4>
                    <br></br>
                    <p className='neuroMobileSectionOverviewParagraphs'><span className='neuroMobileSectionOverviewDivOneReact'>Neighbourhood</span> is an app with full fledge features to get interact with the community in the hard times of pendamic. Also neighbourhood helps people of that community to celebrate their occasions with other members of community.</p>

                    <p className='neuroMobileSectionOverviewParagraphs'>The aim of this project was to <span className='neuroMobileSectionUsersProfilesParagraphsBold' > solve the problem to get interact and celebrate events
                    </span> with other members of that community which helps to get know about each other.</p>
                    <br></br>
                    <h5 className='neuroMobileSectionOverviewHeading'>CLIENT</h5>
                    <img src={reactPic} className='reactLogoNeuroMobile' alt='React Logo' />

                </div>
                <div className='neuroMobileSectionOverviewDivTwo'>
                    <h4 className='neuroMobileSectionOverviewHeading'>ROLE & DURATION</h4>
                    <br></br>
                    <h5 className='neuroMobileSectionUsersProfilesParagraphCustom' >UX Designer </h5>
                    <p className='neuroMobileSectionOverviewCustomParagraph'>User Research, Visual Design, Interaction, Prototyping.</p>
                    <p className='neuroMobileSectionOverviewParagraphs'>Aug 2021 - Nov 2021</p>
                </div>
            </div>

            <div className='neuroMobileSectionTheProblem'>
                <div className='neuroMobileSectionTheProblemMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>The Problem</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Poor communication between the members of community can become a barrier to any community.
                    </p>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>For community members interaction with other members are really hard during this time of pendamic. They are not able to share things with their friends in community. Also they are unable to celebrate any event or occasion within the community. Collaboration for the professionals is very hard like photographer, fitness enthusiasts and many others. Also there is fear to loose social network or  to loose relationship between the neighbours.</p>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Also whenever they want to spend time within their community thereis fear of got inffected by COVID-19.</p>
                </div>
            </div>

            <div className='neuroMobileSectionResearch'>
                <div className='neuroMobileSectionResearchMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Scope of Work & Timeline</h3>
                    <p className='neuroMobileSectionConceptualizationParagraph'>We defined all the scope of work and decided all the timelines before the project started there may be a
                        little bit change of timeline on the basis of priority work. All the activities involved in this
                        project displayed over a 04 months timeline.</p>
                    <img src={scopePic} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div>

            <div className='neuroMobileSectionResearch'>
                <div className='neuroMobileSectionResearchMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Competitor Analysis</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Competitor Analysis helps us to get know more about the Neighbourhood’s product and also their competitiors. It helps us in building a good user experience.</p>
                    <img src={competitiorPic} className='researchPicNeuroMobile' alt='React Logo' />
                    <img src={comp} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div>

            <div className='neuroMobileSectionResearch'>
                <div className='neuroMobileSectionResearchMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Research</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Conducted several meetings with Neighbourhood stakeholders to get better understanding of the product, user needs and business requirements. Also perform different activites with team to get to know more about product and also to enhance the design thinking process.
                    </p>
                    <ul className='neuroMobileSectionConceptualizationMainParagraph'>
                        <li>Understanding the Neighbourhood’s business.</li>
                        <li>Day to day tasks of the user.</li>
                        <li>Defining the user goals.</li>
                        <li>Understanding the users and business needs.</li>
                        <li>Understaning the key features of the product.</li>
                    </ul>

                    <div className='neuroMobileSectionConceptualizationSubDiv'>
                        <h4 className='neighbourMobileSectionResearchMidHeading'>Assumptions & Questions</h4>
                        <p className='neighbourMobileSectionResearchMidParagraph'>Perform this activity with the design team to understand user needs and how team thinks about this product. In this activity we mention questions and assumptions according to user needs.</p>
                    </div>
                    <img src={researchPic} className='researchPicNeuroMobile' alt='React Logo' />
                    <br></br><br></br><br></br><br></br>
                    <div className='neuroMobileSectionConceptualizationSubDiv'>
                        <h4 className='neighbourMobileSectionResearchMidHeading'>Hopes & Fears</h4>
                        <p className='neighbourMobileSectionResearchMidParagraph'>Perform this activity to get more  closer look to the user’s point of view. Also we get to know more about the user’s pain points by this activity.</p>
                    </div>
                    <img src={hopePic} className='researchPicNeuroMobile' alt='React Logo' />
                    <br></br><br></br><br></br><br></br>
                    <div className='neuroMobileSectionConceptualizationSubDiv'>
                        <h4 className='neighbourMobileSectionResearchMidHeading'>Stakeholder Map</h4>
                        <p className='neighbourMobileSectionResearchMidParagraph'>This activity includes list of all the stakeholders and also shows how they interact with each other.</p>
                    </div>
                    <img src={mapPic} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div>


            <div className='neuroMobileSectionInsights'>

                <div className='neuroMobileSectionInsightsMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Insights</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>After conducting countless meetings with stakeholders, contextual inquiry and analyzing the gathered data,
                        I was able to categorize the
                        insights into these <b>3 categories</b>.</p>
                </div>
                <div className='neuroMobileSectionInsightsMainContainer'>
                    <div className='neuroMobileSectionInsightsDivsContainer'>
                        <div className='neurooMobileSectionInsightsSingleDiv'>
                            <img src={resultsPic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='purpleHeadingNeuroMobile'>Join Community</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs'>New members of any community can join the community at anytime according to their interests. They just have to send request to join the community. Also they can join more then one community at a time.</p>
                        </div>
                        <div className='neurooMobileSectionInsightsSingleDiv'>
                            <img src={sharingPic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='purpleHeadingNeuroMobile'>Create Events</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs'>Now community member can create event of any type related to community at anytime with the details of event and also they can make available that event for the rest of the members of community. </p>
                        </div>
                        <div className='neurooMobileSectionInsightsSingleDiv'>
                            <img src={securityPic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='purpleHeadingNeuroMobile'>Join Event</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs'>User can easily join the events of any community. For that they have to send request of join event to the event owner. If he approves then they are able to join that event. He should me must part of that community.</p>
                        </div>

                    </div>
                </div>
            </div>

            <div className='neuroMobileSectionUserProfiles'>
                <div className='neuroMobileSectionResearchMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>User Profiles</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>The research made it evident how different user would use the app diferently. To cater to this I catogerized them into two user profiles based on their tasks and goals.</p>
                    <div >
                        <div className='neuroMobileSectionUserProfilesDivsContainerSpec'>
                            <img src={personPic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='purpleHeadingNeuroMobile'>Community Members</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs'>
                                React Members (Patients) are mostly of senior citizens above the age of 50 years. They are not much tech savvy and don’t know much how to use technology and digital platforms.
                            </p>
                            <h5 className='neuroMobileSectionUsersProfilesHeadings'>Task Performed: </h5>
                            <ul className='neuroMobileSectionUsersProfilesParagraphs'>
                                <li>Check their medical reports and brain health score.</li>
                                <li>Maintain their connections list.</li>
                                <li>Give access to connections for their medical data.</li>
                                <li>Revoke access to connections for their medical data.</li>
                                <li>Track their own brain health and learings to improve it.</li>
                            </ul>
                        </div>
                        <div className='neuroMobileSectionUserProfilesDivsContainerSpec'>
                            <img src={personPic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='purpleHeadingNeuroMobile'>Community Management</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs' >
                                Connections are mostly childrens of senior citizens (patients).  They don’t have much time to visit React centres continously to get information about their parents medical info.
                            </p>
                            <h5 className='neuroMobileSectionUsersProfilesHeadings'>Task Performed: </h5>
                            <ul className='neuroMobileSectionUsersProfilesParagraphs'>
                                <li>Check their loved ones (patrients of React) medical reports and brain health score.</li>
                                <li>Remove connections at anytime and also edit their nickname.</li>
                                <li>Track all the leranings of their loved ones (patients)</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className='neuroMobileSectionResearch'>
                <div className='neuroMobileSectionResearchMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Conceptualization</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>I started creating the user flows, branding, moodboarding and low-fi concepts for primary use cases. After having a go-ahead from the Product Manager, Developers, and Stakeholders on the mockups we began digitalizing designs.</p>
                    <div className='neuroMobileSectionConceptualizationSubDiv'>
                        <h4 className='neuroMobileSectionConceptualizationHeading'>Information Architecture</h4>
                        <p className='neuroMobileSectionConceptualizationParagraph'>Defining the information architecture for MVP of the product - for user</p>
                    </div>
                    <img src={conceptualizationPic} className='researchPicNeuroMobile' alt='React Logo' />
                    <br></br><br></br><br></br>
                    <div className='neuroMobileSectionConceptualizationSubDiv'>
                        <h4 className='neuroMobileSectionConceptualizationHeading'>Moodboarding</h4>
                        <p className='neuroMobileSectionConceptualizationParagraph'>Moodboarding is done after the research and we geather all the visual inspirations here.</p>
                    </div>
                    <img src={moodPic} className='researchPicNeuroMobile' alt='React Logo' />
                    <br></br><br></br><br></br><br></br>
                    <div className='neuroMobileSectionConceptualizationSubDiv'>
                        <h4 className='neuroMobileSectionConceptualizationHeading'>Style Guide</h4>
                        <p className='neuroMobileSectionConceptualizationParagraph'>We followed the base guidelines of Material Design to create styles for Neighboure.</p>
                    </div>
                    <img src={brandPic} className='researchPicNeuroMobile' alt='React Logo' />
                    <br></br><br></br><br></br><br></br>
                    <div className='neuroMobileSectionConceptualizationSubDiv'>
                        <h4 className='neuroMobileSectionConceptualizationHeading'>Wireframes</h4>
                        <p className='neuroMobileSectionConceptualizationParagraph'>Designed lo-fidelity wireframes to get to know better about the features of the app.</p>
                    </div>
                    <img src={wirePic} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div>

            <div className='neuroMobileSectionUserProfiles'>
                <div className='neuroMobileSectionResearchMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>The Solution</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Neighbourhood mobile app makes it easy to get connected with your community and also you are able to build new social connection which will help you get more involved in community. Also this app helps you to groom you as a person to get maximum participation in communities.</p>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <video className='neuroMobileSectionTheSolutionVideo' src={results} autoPlay={"autoplay"} preLoad="auto" loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>On-Boarding to App</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>On-boarding to the app have a sealess experience where user can easily get himself register to the app whether by using email or phone number. We give liberty to user to use email or phone number instead. Also we just ask one information at one stage so that form will not look overwhelming.</p>
                        </div>
                    </div>

                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <video className='neuroMobileSectionTheSolutionVideo' src={video1} autoPlay={"autoplay"} preLoad="auto" loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Explore People</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>In explore people section user can explore people by location also he/she will get suggestion on map on the basis of theri location. Also they are able to add any people they want to add them in their friend’s circle.  Also they can see their friends list ftom here, they can send message to their friends to start a conversation.</p>
                        </div>
                    </div>

                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <video className='neuroMobileSectionTheSolutionVideo' src={grant} autoPlay={"autoplay"} preLoad="auto" loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Explore Communities</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>Here user have liberty to their near by communities by allowing location access to app. Also if they want to be a part of that communities they can join that communities by their interests. Also they can explore their near by communities by category.</p>
                        </div>
                    </div>

                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <video className='neuroMobileSectionTheSolutionVideo' src={learn} autoPlay={"autoplay"} preLoad="auto" loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Explore Events</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>Their all the upcoming events will be shown in form of calender view in a app  so they can easily have access to view details of the calender. They have liberty to explore events by date and day. Also they can view details of the event by click one of them.</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className='neuroMobileSectionUserProfiles'>
                <div className='neuroMobileSectionProjectLearningMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Project Learning</h3>
                    <br></br>
                    <h4 className='neuroMobileSectionProjectLearningHeadings'>1. Simplicity is strength</h4>
                    <p className='neuroMobileSectionProjectLearningMainParagraph'>As a designer, we are often lured
                        by attractive, trendy and out of the box designs. But, We must always remember the ‘why’. The
                        primary goal is to understand the user, their problems and then come up with a design that
                        solves it.</p>

                    <h4 className='neuroMobileSectionProjectLearningHeadings'>2. Prioritize</h4>
                    <p className='neuroMobileSectionProjectLearningMainParagraph'>
                        Create a strategic plan to launch an MVP. This helps deal with out-of-scope requests that
                        could potentially derail the project and helps deliver a quality product in time.</p>

                    <h4 className='neuroMobileSectionProjectLearningHeadings'>3. Seek out feedback early and continually</h4>
                    <p className='neuroMobileSectionProjectLearningMainParagraph'>
                        The trouble with most of us is that we would rather be ruined by praise than saved by criticism. Keeping the stakeholders/users in loop and testing solutions in whatever form (paper, low-fi or hi-fi) as early as possible saves ample amount of time and re-work.</p>
                </div>
            </div>

            <div className='neuroMobileSectionUserProfiles'>
                <div className='neuroMobileSectionInsightsMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>More Projects</h3>
                </div>
                <div className='neuroMobileSectionInsightsDivsContainer'>
                    <div>
                        <a href="/neighbourhood-mobile">
                            <div className='reactNeuroMobilesPicContainer'>
                                <img src={twoMobiles} className='Pic2NeuroMobile' alt='Ali' />
                            </div>
                        </a>
                        <h4 className='neuroMobileSectionMoreProjectsHeading'>Neighbourhood - Mobile App</h4>

                    </div>
                    <div>
                        <a href="/neuro-tab">
                            <div className='reactNeuroPicContainer'>
                                <img src={tabPic1} className='mobilePic1NeuroMobile' alt='Ali' />
                            </div>
                        </a>
                        <h4 className='neuroMobileSectionMoreProjectsHeading'>React Neuro - Tablet App</h4>
                    </div>
                    <div>
                        <a href="/project-ryba">
                            <div className='reactNeuroLaptopPicContainer'>
                                <img src={laptop} className='laptopPic1NeuroMobile' alt='Ali' />
                            </div>
                        </a>
                        <h4 className='neuroMobileSectionMoreProjectsHeading'>Project Ryba - Website</h4>
                    </div>
                </div>
            </div>
            <div className='neuroMobileSpacer'></div>
            {/* <SectionFooter /> */}
        </div>
    )
}
