import React from 'react';
import topbarImage from './images/image.PNG';
import twitterLogo from './images/twitterLogo.png';
import './sectionTestNavBar.modules.css';

export default () => {
    return (
        <div className='mainClassTestNavBar'>
            <img src={topbarImage} className='topbarImage' alt='Ali' />
            <div className='topbarRightContainer'>
                <img src={twitterLogo} className='twitterLogo' alt='Ali' />
                <p className='helpText'>Help | Support </p>
                <button className='signInButton'>Sign-in</button>
            </div>
        </div>
    )
}