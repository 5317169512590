import React from 'react';
import hashirPic1 from './images/h17.png';
import hiIcon from './images/hiIcon.png';
import hiIcon2 from './images/hi.png';
import TextLoop from "react-text-loop";
import dri from './images/dri.svg';
import ins from './images/ins.svg';
import beh from './images/beh.svg';
import lin from './images/lin.svg';
import yu from './images/yu.svg';
import up from './images/up.svg';
import './sectionHero.modules.css';
import Typewriter from 'typewriter-effect';

export default () => {
    return (
        <div className='mainClassHero'>


            {/* <div className="bubbles"> */}
                {/* <div className="bubble"></div>
                <div className="bubble2"></div> */}


<img src={hiIcon2} className='heroHiIcon' alt='Ali' />
            <div >
                <h2 className='heroHelloIam'>Hello 👋, I am Jay a 
                {/* <b>Senior UX/UI Designer</b></h2> */}
              <span>  <b>
                <Typewriter 
            options={{
              strings: ['Senior UX/UI Designer', 'Senior UX/UI Designer', 'Senior UX/UI Designer'],
              autoStart: true,
              loop: true,
              delay: 60,
              pauseFor: 2552,
              deleteSpeed: 60,
            }}
          /> 
          </b> </span> </h2>


            </div>
            
                <p className='heroExplanation'>I specialise in creative and innovative designs for various marketing collateral, branding, websites, apps and UI/UX design with many years of experience. I am focused on creating meaningful experiences through design.</p>
            
            {/* <div>
                <div className='socialMediaIconsContainerHero'>
                    <a href="https://instagram.com/" target="_blank"><img src={ins} className='socialMediaIconHero' alt='Twitter Logo' /></a>
                    <a href="https://www.linkedin.com/" target="_blank"><img src={lin} className='socialMediaIconHero' alt='Linkedin Logo' /></a>
                    <a href="https://dribbble.com/" target="_blank"><img src={dri} className='socialMediaIconHero' alt='Github Logo' /></a>
                    <a href="https://www.behance.net/" target="_blank"><img src={beh} className='socialMediaIconHero' alt='Instagram Logo' /></a>
                    <a href="https://www.youtube.com/" target="_blank"><img src={yu} className='socialMediaIconHero' alt='Youtube Logo' /></a>
                </div>
            </div> */}
            {/* </div> */}
        </div>
    )
}