import React from 'react';
import './neuroMobile.modules.css';
import threeMobiles from './images/threeMobiles.png';
import reactPic from './images/reactPic.PNG';
import researchPic from './images/researchPic.png';
import competitiorPic from './images/competitiorPic.png';
import resultsPic from './images/resultsPic.svg';
import sharingPic from './images/sharingPic.svg';
import securityPic from './images/securityPic.svg';
import personPic from './images/person.svg';
import scopePic from './images/scopePic.png';
import conceptualizationPic from './images/conceptualization.png';
import moodPic from './images/mood.png';
import brandPic from './images/brand.png';
import twoMobiles from './../sectionLatestWork/images/twoMobiles.png';
import laptop from './../sectionLatestWork/images/laptop.png';
import tabPic1 from './../sectionLatestWork/images/tabPic1.png';
import leftAero from './images/leftAero.png';
// import video1 from "./videos/connection.mp4";
// import grant from "./videos/grant.mp4";
// import learn from "./videos/learn.mp4";
// import results from "./videos/results.mp4";
import video1 from "./videos/connection.mov";
import grant from "./videos/grant.mov";
import learn from "./videos/learn.mov";
import results from "./videos/results.mov";
import SectionTopBar from '../sectionTopBar/sectionTopBar';
import SectionFooter from '../sectionFooter/sectionFooter';

export default () => {
    return (
        <div className='mainClassNeuroMobileWebsite'>
            <SectionTopBar styleColor="#F1F0FC" />
            <div className='backAeroContainer'>
                <a className='backAeroContainerSub' href="javascript:history.back()"><img src={leftAero} className='leftAero' alt='Ali' /></a>
            </div>

            <div className='mainNeuroMobileContainer'>
                <h3 className='neuroMobileAppHeading'>React Neuro - Mobile App</h3>
                <p className='neuroMobileAppParagraph'>A single app to get update about your and yours loved one’s mental health.</p>

                <img src={threeMobiles} className='threeMobiles' alt='Ali' />

            </div>

            <div className='neuroMobileSectionOverview'>
                <div className='neuroMobileSectionOverviewDivOne'>
                    <h4 className='neuroMobileSectionOverviewHeading'>OVERVIEW</h4>
                    <br></br>
                    <p className='neuroMobileSectionOverviewParagraphs'><span className='neuroMobileSectionOverviewDivOneReact'>React Neuro</span> is a digital platform designed to change the way we engage with our brain health.
                        Building on decades of breakthrough research, we use Virtual Reality to probe deeply into the
                        neural circuits that make us tick.</p>

                    <p className='neuroMobileSectionOverviewParagraphs'>The aim of this project was to <span className='neuroMobileSectionUsersProfilesParagraphsBold' >solve the problem to get updates
                        regarding reports, trends and learnings</span> for React’s patients
                        through an app.</p>
                    <br></br>
                    <h5 className='neuroMobileSectionOverviewHeading'>CLIENT</h5>
                    <img src={reactPic} className='reactLogoNeuroMobile' alt='React Logo' />

                </div>
                <div className='neuroMobileSectionOverviewDivTwo'>
                    <h4 className='neuroMobileSectionOverviewHeading'>ROLE & DURATION</h4>
                    <br></br>
                    <h5 className='neuroMobileSectionUsersProfilesParagraphCustom' >UX Designer | VentureDive</h5>
                    <p className='neuroMobileSectionOverviewCustomParagraph'>User Research, Visual Design, Interaction, Prototyping.</p>
                    <p className='neuroMobileSectionOverviewParagraphs'>Nov 2021 - Feb 2022</p>
                </div>
            </div>

            <div className='neuroMobileSectionTheProblem'>
                <div className='neuroMobileSectionTheProblemMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>The Problem</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Poor communication between the operator and patient can become a barrier to an organization’s success.</p>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>For operator managing multiple tasks can be difficult, especially when you have to
                        manage a large number of patients at a same time. At this moment there is lack of proper
                        guidence and communication regarding the reports of patients.</p>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Also whenever patients need to check their reports they have to visit react centre everytime.</p>
                </div>
            </div>

            <div className='neuroMobileSectionResearch'>
                <div className='rybaSectionScopeMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Scope of Work & Timeline</h3>
                    <p className='neuroMobileSectionConceptualizationParagraph'>We defined all the scope of work and
                        decided all the timelines before the project started there may be a little bit change of timeline
                        on the basis of priority work. All the activities involved in this project displayed over a 04
                        months timeline.</p>
                    <img src={scopePic} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div>

            <div className='neuroMobileSectionResearch'>
                <div className='neuroMobileSectionResearchMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Research</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Conducted several meetings with React Neuro stakeholders to get better understanding of
                        the product, user needs and business requirements.</p>
                    <ul className='neuroMobileSectionConceptualizationMainParagraph'>
                        <li>Understanding the React Neuro’s business.</li>
                        <li>Day to day tasks of the user.</li>
                        <li>On which criteria reports are generated.</li>
                        <li>Understanding of graphical representation of results.</li>
                        <li>Understaning of the exams, domains and metrics of React Neuro.</li>
                    </ul>
                    <img src={researchPic} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div>

            <div className='neuroMobileSectionResearch'>
                <div className='neuroMobileSectionResearchMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Competitor Analysis</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Competitor Analysis helps us to get know more about the React’s product and
                        also their competitiors. It helps us in
                        building a good user experience.</p>
                    <img src={competitiorPic} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div>

            <div className='neuroMobileSectionInsights'>

                <div className='neuroMobileSectionInsightsMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Insights</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>After conducting countless meetings with stakeholders, contextual inquiry and analyzing the gathered data,
                        I was able to categorize the
                        insights into these <b>3 categories</b>.</p>
                </div>
                <div className='neuroMobileSectionInsightsMainContainer'>
                    <div className='neuroMobileSectionInsightsDivsContainer'>
                        <div className='neuroMobileSectionInsightsSingleDiv'>
                            <img src={resultsPic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='purpleHeadingNeuroMobile'>Results</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs'>Now members of React (patient) can easily view their results of the exams conducted on their mobile phone.
                                They don’t need to visit the React centre just to check their results and overall brain health score.</p>
                        </div>
                        <div className='neuroMobileSectionInsightsSingleDiv'>
                            <img src={sharingPic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='purpleHeadingNeuroMobile'>Sharing Results</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs'>You can share your all the medical info or data with your connections also you can revoke access of your data to specific connection at anytime. And also you
                                can limit your connections to view certain medical results.</p>
                        </div>
                        <div className='neuroMobileSectionInsightsSingleDiv'>
                            <img src={securityPic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='purpleHeadingNeuroMobile'>Security</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs'>Medical reports of the patients , learning progress and brain health score
                                will be highly confidential and did not easily accessible to any other person who is not
                                registered with React Neuro.</p>
                        </div>

                    </div>
                </div>
            </div>

            <div className='neuroMobileSectionUserProfiles'>
                <div className='neuroMobileSectionResearchMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>User Profiles</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>The research made it evident how different user would use the app diferently.
                        To cater to this I catogerized them into two user profiles based on their tasks and goals.</p>
                    <div >
                        <div className='neuroMobileSectionUserProfilesDivsContainerSpec'>
                            <img src={personPic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='purpleHeadingNeuroMobile'>React Members (Patients)</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs'>
                                React Members (Patients) are mostly of senior citizens above the age of 50 years. They are not much tech savvy and don’t know much how to use technology and digital platforms.
                            </p>
                            <h5 className='neuroMobileSectionUsersProfilesHeadings'>Task Performed: </h5>
                            <ul className='neuroMobileSectionUsersProfilesParagraphs'>
                                <li>Check their medical reports and brain health score.</li>
                                <li>Maintain their connections list.</li>
                                <li>Give access to connections for their medical data.</li>
                                <li>Revoke access to connections for their medical data.</li>
                                <li>Track their own brain health and learings to improve it.</li>
                            </ul>
                        </div>
                        <div className='neuroMobileSectionUserProfilesDivsContainerSpec'>
                            <img src={personPic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='purpleHeadingNeuroMobile'>Connections (Loved ones of patients)</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs' >
                                Connections are mostly childrens of senior citizens (patients).  They don’t have much time to visit React centres continously to get information about their parents medical info.
                            </p>
                            <h5 className='neuroMobileSectionUsersProfilesHeadings'>Task Performed: </h5>
                            <ul className='neuroMobileSectionUsersProfilesParagraphs'>
                                <li>Check their loved ones (patrients of React) medical reports and brain health score.</li>
                                <li>Remove connections at anytime and also edit their nickname.</li>
                                <li>Track all the leranings of their loved ones (patients)</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='neuroMobileSectionResearch'>
                <div className='neuroMobileSectionResearchMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Conceptualization</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>I started creating the user flows, branding, moodboarding and low-fi concepts for primary use cases.
                        After having a go-ahead from the Product Manager, Developers, and Stakeholders on the mockups
                        we began digitalizing designs.</p>
                    <div className='neuroMobileSectionConceptualizationSubDiv'>
                        <h4 className='neuroMobileSectionConceptualizationHeading'>User Flow</h4>
                        <p className='neuroMobileSectionConceptualizationParagraph'>Defining the user flows for MVP of the product - for both patient and connection.</p>
                    </div>
                    <img src={conceptualizationPic} className='researchPicNeuroMobile' alt='React Logo' />
                    <br></br><br></br><br></br>
                    <div className='neuroMobileSectionConceptualizationSubDiv'>
                        <h4 className='neuroMobileSectionConceptualizationHeading'>Moodboarding</h4>
                        <p className='neuroMobileSectionConceptualizationParagraph'>Moodboarding is done after the research and we geather all the visual inspirations here.</p>
                    </div>
                    <img src={moodPic} className='researchPicNeuroMobile' alt='React Logo' />
                    <br></br><br></br><br></br><br></br>
                    <div className='neuroMobileSectionConceptualizationSubDiv'>
                        <h4 className='neuroMobileSectionConceptualizationHeading'>Branding</h4>
                        <p className='neuroMobileSectionConceptualizationParagraph'>Defining a basic brand identify for the React product.</p>
                    </div>
                    <img src={brandPic} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div>

            <div className='neuroMobileSectionUserProfiles'>
                <div className='neuroMobileSectionResearchMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>The Solution</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>React Neuro’s mobile app makes
                        it easy to keep up-to-date and check their medical reports – wherever they are – using a
                        simple-to-use chat interface. Efficiently manage connections and also able to check connections
                        results too. They can manage their connections easily.</p>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <video className='neuroMobileSectionTheSolutionVideo' src={results} autoPlay={"autoplay"} preLoad="auto" loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>View Results</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>Now patients can easily view their reports or
                                results. And also they can track their progress of learnings. Also they have easy to understand data for their
                                reports with the comparison of their previus reports. There is no medical graph included which create imbiguity
                                for users.</p>
                        </div>
                    </div>

                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <video className='neuroMobileSectionTheSolutionVideo' src={video1} autoPlay={"autoplay"} loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>View Connections Results</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>Now loved ones (connections)
                                of patients can easily view patient’s reports or results. And also they can track their
                                progress of learnings. Also they have easy to understand data for their reports with the
                                comparison of their previus reports. There is no medical graph included which create
                                imbiguity for users.</p>
                        </div>
                    </div>

                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <video className='neuroMobileSectionTheSolutionVideo' src={grant} autoPlay={"autoplay"} loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Grant or Revoke Access</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>React’s patients now can
                                grant and revoke access to their specific connection at anytime. Also they have right to
                                share results of some specific domains with their connections. Now they have access to
                                manage their connections. </p>
                        </div>
                    </div>

                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <video className='neuroMobileSectionTheSolutionVideo' src={learn} autoPlay={"autoplay"} preLoad="auto" loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Learn About React</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>In the mobile app there is a
                                allocated screen to learn more about React which helps user to get know more about the React and
                                also it help user to understand the working flow of React. Last but not least also it helps
                                user to read artciles to maintain their mental health.</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className='neuroMobileSectionUserProfiles'>
                <div className='neuroMobileSectionProjectLearningMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Project Learning</h3>
                    <br></br>
                    <h4 className='neuroMobileSectionProjectLearningHeadings'>1. Simplicity is strength</h4>
                    <p className='neuroMobileSectionProjectLearningMainParagraph'>As a designer, we are often lured
                        by attractive, trendy and out of the box designs. But, We must always remember the ‘why’. The
                        primary goal is to understand the user, their problems and then come up with a design that
                        solves it.</p>

                    <h4 className='neuroMobileSectionProjectLearningHeadings'>2. Prioritize</h4>
                    <p className='neuroMobileSectionProjectLearningMainParagraph'>
                        Create a strategic plan to launch an MVP. This helps deal with out-of-scope requests that
                        could potentially derail the project and helps deliver a quality product in time.</p>

                    <h4 className='neuroMobileSectionProjectLearningHeadings'>3. Seek out feedback early and continually</h4>
                    <p className='neuroMobileSectionProjectLearningMainParagraph'>
                        The trouble with most of us is that we would rather be ruined by praise than saved by criticism. Keeping the stakeholders/users in loop and testing solutions in whatever form (paper, low-fi or hi-fi) as early as possible saves ample amount of time and re-work.</p>
                </div>
            </div>

            <div className='neuroMobileSectionUserProfiles'>
                <div className='neuroMobileSectionInsightsMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>More Projects</h3>
                </div>
                <div className='neuroMobileSectionInsightsDivsContainer'>
                    <div>
                        <a href="/neighbourhood-mobile">
                            <div className='reactNeuroMobilesPicContainer'>
                                <img src={twoMobiles} className='Pic2NeuroMobile' alt='Ali' />
                            </div>
                        </a>
                        <h4 className='neuroMobileSectionMoreProjectsHeading'>Neighbourhood - Mobile App</h4>

                    </div>
                    <div>
                        <a href="/neuro-tab">
                            <div className='reactNeuroPicContainer'>
                                <img src={tabPic1} className='mobilePic1NeuroMobile' alt='Ali' />
                            </div>
                        </a>
                        <h4 className='neuroMobileSectionMoreProjectsHeading'>React Neuro - Tablet App</h4>
                    </div>
                    <div>
                        <a href="/project-ryba">
                            <div className='reactNeuroLaptopPicContainer'>
                                <img src={laptop} className='laptopPic1NeuroMobile' alt='Ali' />
                            </div>
                        </a>
                        <h4 className='neuroMobileSectionMoreProjectsHeading'>Project Ryba - Website</h4>
                    </div>
                </div>
            </div>
            <div className='neuroMobileSpacer'></div>
            {/* <SectionFooter /> */}
        </div>
    )
}
