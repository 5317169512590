import React from 'react';
import dri from './images/dri.svg';
import ins from './images/ins.svg';
import beh from './images/beh.svg';
import lin from './images/lin.svg';
import cpy from './images/cpy.svg';
import up from './images/up.svg';
import Marquee from 'react-double-marquee';
import './sectionFooter.modules.css';

export default (props) => {
    const page = props.page ? props.page : "none";
    const onScroll = () => {
        if (typeof window === 'object') window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    return (
        <div id="footer" className='mainClassFooter'>
            <div className='textContentFooter'>

                <div className='mySliderClass'
                    style={{
                        width: '100%',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <Marquee direction="left" childMargin='20' speed='0.08' delay='500'  >
                        Pixel perfect  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Things with purpose  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Minimalist &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Working well with others &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Workplace &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Believe in teamwork  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Working next to developers
                    </Marquee>
                </div>

                <div className='textContentFooterLastLine'>
                    <div className='cpyDivContainer'> <img src={cpy} className='cpyIcon' alt='Instagram Logo' /><p className='sectionFooterAllRightsParagraph'>2023 Jay. All Rights Reserved.</p></div>

                    <div className='upDivContainer' onClick={onScroll}><p className='sectionFooterUpParagraph'>Back to top</p><img src={up} className='upIcon' alt='Instagram Logo' /></div>

                    <div className='cpyDivContainerMobile'> <img src={cpy} className='cpyIcon' alt='Instagram Logo' /><p className='sectionFooterAllRightsParagraph'>2023 Jay. All Rights Reserved.</p></div>

                    {/* <div>
                        <div className='socialMediaIconsContainer'>
                            <a href="https://instagram.com/hashgraphy_?r=nametag" target="_blank"><img src={ins} className='socialMediaIcon' alt='Twitter Logo' /></a>
                            <a href="https://www.linkedin.com/in/hashir-khan-b06259172/" target="_blank"><img src={lin} className='socialMediaIcon' alt='Linkedin Logo' /></a>
                            <a href="https://dribbble.com/hashirkhan1947" target="_blank"><img src={dri} className='socialMediaIcon' alt='Github Logo' /></a>
                            <a href="https://www.behance.net/hashirkhan1947" target="_blank"><img src={beh} className='socialMediaIcon' alt='Instagram Logo' /></a>
                        </div>
                    </div> */}
                </div>


                {/* <h3 className='neuroMobileSectionResearchMainDivHeading'>Let’s Connect 👋</h3>

                <p className='neuroMobileSectionFooterLearningMainParagraph'> Feel free to reach out for collaborations or just a friendly hello 😃</p>
                <a className='footerMailLink' href="mailto:hashirkhan1947@gmail.com"> hashirkhan1947@gmail.com</a>

                <div className='socialMediaIconsContainer'>
                    <a href="https://www.facebook.com/hashir.khan.9256028" target="_blank"><img src={facebookLogo} className='socialMediaIcon' alt='Facebook Logo' /></a>
                    <a href="https://instagram.com/hashgraphy_?r=nametag" target="_blank"><img src={instagramLogo} className='socialMediaIcon' alt='Twitter Logo' /></a>
                    <a href="https://dribbble.com/hashirkhan1947" target="_blank"><img src={dribleLogo} className='socialMediaIcon' alt='Linkedin Logo' /></a>
                    <a href="https://www.behance.net/hashirkhan1947" target="_blank"><img src={behanceLogo} className='socialMediaIcon' alt='Github Logo' /></a>
                    <a href="https://www.linkedin.com/in/hashir-khan-b06259172/" target="_blank"><img src={linkedinLogo} className='socialMediaIcon' alt='Instagram Logo' /></a>
                </div> */}




                {/* <p className='sectionFooterDesignedParagraph'>Designed, Developed and Deployed by <a href="/" className='sectionFooterDesignedByAli'>Ali Sarib</a></p> */}
            </div>

            {/* <p className='sectionFooterDesignedParagraphMobile'>Designed, Developed and Deployed by <a href="/" className='sectionFooterDesignedByAli'>Ali Sarib</a></p> */}

        </div>
    )
}