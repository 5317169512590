import React from 'react';
import './valiant.modules.css';
import bg1 from './images/bg1.png';
import bg2 from './images/bg2.png';
import bgM from './images/bgMobile.png';
import betfairLogo from './images/betfairLogo.png';
import threeButtons from './images/threeButtons.svg';
import overview from './images/overview.svg';
import problem from './images/problem.svg';
import solution from './images/solution.svg';
import scopeSVG from './images/scope.svg';
import scope1 from './images/scope1.svg';
import scope2 from './images/scope2.svg';
import scope3 from './images/scope3.svg';
import scope4 from './images/scope4.svg';
import LongTermGoal from './images/ltGoal.svg';
import accountant from './images/Accountant.svg';
import builder from './images/builder.svg';
import professional from './images/professional.svg';
import userFlows from './images/userFlows.svg';
import broker from './images/broker.svg';
import infoSVG from './images/info.svg';
import ltg1 from './images/ltg1.png';
import design1 from './images/design1.png';
import design2 from './images/design2.png';
import s1 from './images/s1.png';
import s2 from './images/s2.png';
import s22 from './images/s22.png';
import s3 from './images/s3.png';
import s4 from './images/s4.png';
import key from './images/key.svg';
import video1 from "./videos/1.mov";
import video2 from "./videos/2.mov";
import video3 from "./videos/3.mov";
import video4 from "./videos/4.mov";
import book from '../sectionApartFromWork/images/book.svg';
import pod from '../sectionApartFromWork/images/pod.svg';

import reactPic from './images/reactPic.PNG';
import researchPic from './images/researchPic.png';
import competitiorPic from './images/competitiorPic.png';
import resultsPic from './images/resultsPic.svg';
import sharingPic from './images/sharingPic.svg';
import securityPic from './images/securityPic.svg';
import personPic from './images/person.svg';
import scopePic from './images/scopePic.png';
import conceptualizationPic from './images/conceptualization.png';
import moodPic from './images/mood.png';
import brandPic from './images/brand.png';
import twoMobiles from './../sectionLatestWork/images/twoMobiles.png';
import laptop from './../sectionLatestWork/images/laptop.png';
import tabPic1 from './../sectionLatestWork/images/tabPic1.png';
import leftAero from './images/leftAero.svg';
import SectionTopBar from '../sectionTopBar/sectionTopBar';
import SectionFooter from '../sectionFooter/sectionFooter';

export default () => {
    return (
        <div className='mainClassNeuroMobileWebsite'>
            <SectionTopBar styleColor="#FFFFFF" />
            {/* <div className='backAeroContainer'>
                <a className='backAeroContainerSub' href="javascript:history.back()"><img src={leftAero} className='leftAero' alt='Ali' /></a>
            </div> */}

            {/* <div className='mainNeuroMobileContainer'>
                <h3 className='neuroMobileAppHeading'>betfair</h3>
                <p className='neuroMobileAppParagraph'>A single app to get update about your and yours loved one’s mental health.</p>

                <img src={threeMobiles} className='threeMobiles' alt='Ali' />

            </div> */}

            <div className='mainClassValiantHero'>
                <div className='subClassBetfairHero'>
                    <div className='leftClassBetfairHero'>

                        <div className='innerleftClassBetfairHero'>

                            <div className='backAeroContainerBetfairHero'>
                                <a className='backAeroContainerBetfairHeroSub' href="javascript:history.back()"><img src={leftAero} className='leftAero' alt='Ali' /></a>
                            </div>

                            <img src={betfairLogo} className='LogoBetfair' alt='Ali' />

                            <h1 className='betfairHeroMainHeading'>All for loan in one place</h1>

                            <h1 className='racvHeroSubHeading'>Australia's trusted business loan marketplace. Discover your loan options in minutes with lender matches tailored to your business needs.</h1>
                            <img src={threeButtons} className='threeButtons' alt='Ali' />
                        </div>

                    </div>

                    <div className='heroMainImageBackgroundBetfair'>
                        <img src={bg2} className='imageHeroValiant' alt='Ali' />
                        <img src={bgM} className='imageHeroValiantMobile' alt='Ali' />
                    </div>
                </div>

            </div>

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionOverview'>
                    <div className='betfairSectionOverviewDivOne'>
                        <h4 className='betfairSectionOverviewHeading'>ROLE</h4>
                        {/* <br></br> */}
                        <p className='betfairSectionOverviewCustomParagraph'>Senior UX/UI Designer</p>

                    </div>
                    <div className='betfairSectionOverviewDivOne'>
                        <h4 className='betfairSectionOverviewHeading'>RESPONSIBLITY</h4>
                        {/* <br></br> */}
                        <p className='betfairSectionOverviewCustomParagraph'>End-to-End Design Process</p>
                    </div>
                    <div className='betfairSectionOverviewDivOne'>
                        <h4 className='betfairSectionOverviewHeading'>TEAM</h4>
                        {/* <br></br> */}
                        <p className='betfairSectionOverviewCustomParagraph'>X-functional team of six Solo Designer</p>
                    </div>
                </div>
            </div>

            {/*Section Overview Problem Solution  */}
            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <div className='betfairSectionProblemChallangeDivOne'>
                        <img src={overview} className='ProblemChallangeIcons' alt='Ali' />
                        <p className='betfairSectionProblemChallangeParagraph'>Valiant Finance was founded in 2015 in Sydney as a digital marketplace which connects small businesses of Australia with potential lenders. The founders identified a niche in the market created by restricted access to capital that many of Australia's two million small business were experiencing at the time.</p>
                    </div>
                    <div className='betfairSectionProblemChallangeDivOne'>
                        <img src={solution} className='ProblemChallangeIcons' alt='Ali' />
                        <p className='betfairSectionProblemChallangeParagraph'>The problem was their marketing site is not the representation of what they offer and they do. It was done a long time ago. They needed to redefine the personas, user flows, sitemap and IA to create a new marketing website which is based on the current business personas.</p>
                    </div>
                    <div className='betfairSectionProblemChallangeDivOne'>
                        <img src={problem} className='ProblemChallangeIcons' alt='Ali' />
                        <p className='betfairSectionProblemChallangeParagraph'>The business objective of Valiant Finance Australia is to make the process of securing business finance simpler, faster, and more transparent for SMEs in Australia. </p>
                    </div>
                </div>
            </div>


            {/* <div className='neuroMobileSectionOverview'>
                <div className='neuroMobileSectionOverviewDivOne'>
                    <h4 className='neuroMobileSectionOverviewHeading'>OVERVIEW</h4>
                    <br></br>
                    <p className='neuroMobileSectionOverviewParagraphs'><span className='neuroMobileSectionOverviewDivOneReact'>React Neuro</span> is a digital platform designed to change the way we engage with our brain health.
                        Building on decades of breakthrough research, we use Virtual Reality to probe deeply into the
                        neural circuits that make us tick.</p>

                    <p className='neuroMobileSectionOverviewParagraphs'>The aim of this project was to <span className='neuroMobileSectionUsersProfilesParagraphsBold' >solve the problem to get updates
                        regarding reports, trends and learnings</span> for React’s patients
                        through an app.</p>
                    <br></br>
                    <h5 className='neuroMobileSectionOverviewHeading'>CLIENT</h5>
                    <img src={reactPic} className='reactLogoNeuroMobile' alt='React Logo' />

                </div>
                <div className='neuroMobileSectionOverviewDivTwo'>
                    <h4 className='neuroMobileSectionOverviewHeading'>ROLE & DURATION</h4>
                    <br></br>
                    <h5 className='neuroMobileSectionUsersProfilesParagraphCustom' >UX Designer | VentureDive</h5>
                    <p className='neuroMobileSectionOverviewCustomParagraph'>User Research, Visual Design, Interaction, Prototyping.</p>
                    <p className='neuroMobileSectionOverviewParagraphs'>Nov 2021 - Feb 2022</p>
                </div>
            </div> */}

            {/* <div className='neuroMobileSectionTheProblem'>
                <div className='neuroMobileSectionTheProblemMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>The Problem</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Poor communication between the operator and patient can become a barrier to an organization’s success.</p>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>For operator managing multiple tasks can be difficult, especially when you have to
                        manage a large number of patients at a same time. At this moment there is lack of proper
                        guidence and communication regarding the reports of patients.</p>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Also whenever patients need to check their reports they have to visit react centre everytime.</p>
                </div>
            </div> */}

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>Scope of Work and Timeline</h3>
                    <p className='betfairSectionScopeParagraph'>All the activities involved in this project displayed over a 04 months timeline</p>

                    <div className='betfairSectionScope'>
                        <img src={scope1} className='ScopeIcons' alt='Ali' />
                        <img src={scope2} className='ScopeIcons' alt='Ali' />
                        <img src={scope3} className='ScopeIcons' alt='Ali' />
                        <img src={scope4} className='ScopeIcons' alt='Ali' />
                    </div>

                    {/* <img src={scopeSVG} className='researchPicNeuroMobile' alt='React Logo' /> */}
                </div>
            </div>

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>Long Term Goal</h3>
                    <p className='betfairSectionScopeParagraph'>Defining the long term goal i-e the optimistic view of the world where our product is successful.</p>

                    <img src={LongTermGoal} className='betfairLongTermPic' alt='React Logo' />
                    <img src={ltg1} className='betfairLongTermPic1' alt='React Logo' />
                </div>
            </div>

            {/* <div className='neuroMobileSectionResearch'>
                <div className='rybaSectionScopeMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Scope of Work & Timeline</h3>
                    <p className='neuroMobileSectionConceptualizationParagraph'>We defined all the scope of work and
                        decided all the timelines before the project started there may be a little bit change of timeline
                        on the basis of priority work. All the activities involved in this project displayed over a 04
                        months timeline.</p>
                    <img src={scopePic} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div> */}

            <div className='neuroMobileSectionInsights'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>Information Architecture</h3>
                    <p className='betfairSectionScopeParagraph'>Refining the information architecture of Valiant’s business</p>

                    <img src={infoSVG} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div>

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>User Personas - Referrer</h3>
                    <p className='betfairSectionScopeParagraph'>We defined personas for two types of end users: Referrer and Direct User</p>

                    <img src={accountant} className='researchPicNeuroMobile' alt='React Logo' />
                    <br></br>
                    <img src={broker} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div>

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>User Personas - Direct Users</h3>
                    <p className='betfairSectionScopeParagraph'>We defined personas for two types of end users: Referrer and Direct User</p>

                    <img src={builder} className='researchPicNeuroMobile' alt='React Logo' />
                    <br></br>
                    <img src={professional} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div>

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>User Flows Mapping</h3>
                    <p className='betfairSectionScopeParagraph'>Defining the user flows for MVP of the product - for both personas</p>

                    <img src={userFlows} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div>

            <div className='neuroMobileSectionInsights'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>Design Principles</h3>
                    <p className='betfairSectionScopeParagraph'>Defining the top 5-6 design principles for the Valiant Finance platform</p>
                </div>
                <div className='neuroMobileSectionInsightsMainContainer'>
                    <div className='rybaSectionInsightsDivsContainer'>
                        <div className='rybaSectiondesignPrincipalsdiv'>
                            <div className='valiantSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Simple </p>
                            </div>
                            <div className='valiantSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Human </p>
                            </div>


                            <div className='valiantSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Activates Gratitude </p>
                            </div>
                        </div>
                        <div className='rybaSectiondesignPrincipalsdiv'>
                            <div className='valiantSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Social </p>
                            </div>


                            <div className='valiantSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Instant </p>
                            </div>
                            <div className='valiantSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Accessible </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>Valiant’s Foundation Kit</h3>
                    <p className='betfairSectionScopeParagraph'>Defining a basic foundations of brand identify for the Valiant Finance</p>
                    <br></br>
                    <div className='valiantSectionHomeImgMain'>
                        <img src={design1} className='rybaSectionHomeImg1' alt='React Logo' />
                        <br></br>
                        <img src={design2} className='rybaSectionHomeMobileImg1' alt='React Logo' />
                    </div>
                </div>
            </div>

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>High-Fidelity Designs</h3>
                    <p className='betfairSectionScopeParagraph'>Website mockups and prototype designed for the users</p>
                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Valiant Finance Homepage</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>Through our redesign, we aimed to improve the user experience of Valiant's homepage by creating a more intuitive and user-friendly interface. Our goal was to ensure that users could easily navigate the website and achieve their desired goals with minimal effort, streamlining the process and reducing any potential frustration or confusion.</p>
                        </div>
                        {/* <img src={s1} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video1} autoPlay={"autoplay"} loop controls="controls" />
                    </div>
                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <img src={s22} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' />
                        {/* <video className='neuroMobileSectionTheSolutionVideo' src={video2} autoPlay={"autoplay"} loop controls="controls" /> */}
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Loan Calculator</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>We introduced a new loan calculator feature that allows users to easily calculate their loan payments. Depending on the amount entered, users are also given the option to learn more about our sponsors and partners. This new feature provides added convenience and transparency for our users, enabling them to make informed decisions when it comes to taking out a loan.</p>
                        </div>
                    </div>
                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Savanna Homepage</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>Savanna is a platform where referrers earn commissions for bringing in new clients to users. It helps connect businesses with interested customers while providing an opportunity for referrers to earn income.</p>
                        </div>
                        {/* <img src={s3} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video3} autoPlay={"autoplay"} loop controls="controls" />
                    </div>
                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        {/* <img src={s4} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video4} autoPlay={"autoplay"} loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Blogs - Learn More</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>During the redesign process, we focused on improving the usability of the blogs by implementing a tagging system for different categories. This allows users to easily navigate and make their selections based on their specific needs and interests.</p>
                        </div>
                    </div>

                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <img src={key} className='neuroMobileSectionTheSolutionVideo1' alt='React Logo' />
                        <div className='neuroMobileSectionTheSolutionSubDiv1'>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>Working with Valiant was a valuable learning experience. By following our established design process, we developed a solution that was informed by thorough research and validation. Throughout the project, we gained insightful feedback from both stakeholders and end-users, which allowed us to shape the solution into a more effective and user-friendly design.</p>
                        </div>
                    </div>

                </div>
            </div>
            {/* <br></br> */}

            {/* <div className='betfairSectionOverviewMain'>
                <div id="apartFromWork" className='mainClassApartFromWork'>
                    <h3 className='apartFromWorkText1' >Want to explore more, here are options</h3>
                    <br></br>
                    <div className='apartFromWorkPicsContainer' >
                        <img src={book} className='apartFromWorkPics' alt='Ali' />
                        <div className='latestWorkEmptyContainer'></div>
                        <div className='latestWorkEmptyHorizontalContainer'></div>
                        <img src={pod} className='apartFromWorkPics' alt='Ali' />
                    </div>

                </div>
            </div> */}

            {/* <div className='neuroMobileSpacer'></div> */}
            {/* <SectionFooter /> */}
        </div>
    )
}
