import React from 'react';
import Container from 'react-bootstrap/Container';
import SectionTopBar from './sectionTopBar/sectionTopBar';

export default function NotFound(){
    return(
        <Container>
            <SectionTopBar page="about" />
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <h1 className="text-center">Page Not Found</h1>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </Container>
    )
}
