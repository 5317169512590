import React from 'react';
import './aboutPage.modules.css';
import cover from './aboutCover.png';
import jay from './aboutJay.svg';
import one from './one.png';
import two from './two.png';
import three from './three.png';
import four from './four.png';
import five from './five.png';
import six from './six.png';
import resultsPic from './about111.svg';
import sharingPic from './about222.svg';
import securityPic from './about333.svg';
import book from '../sectionApartFromWork/images/book.svg';
import pod from '../sectionApartFromWork/images/pod.svg';
import SectionTopBar from '../sectionTopBar/sectionTopBar';
import SectionFooter from '../sectionFooter/sectionFooter';
import Container from 'react-bootstrap/Container'

export default () => {
    return (
        <div className='mainClassAboutWebsite'>

            <SectionTopBar page="about" />
            <div className='mainClassAboutContainer'>
                <div className='heroClassAboutContainer'>
                    {/* <img src={jay} className='aboutCoverImage' alt='Jay' /> */}

                    <div className='aboutParagraphOneContainer'>

                        {/* <h3 className='aboutParagraphOneHeading'>I am Hashir, a UX Designer from Pakistan.</h3> */}
                        <h3 className='aboutParagraphOne'>I am Jay, a Senior UX/UI Designer from Australia.</h3>

                        <p className='aboutParagraphOne'>I specialise in creative and innovative designs for various marketing collateral, branding, websites, apps and UI/UX design with over 7 years of industry experience . I am focused on creating meaningful experiences through design. </p>

                        <p className='aboutParagraphOne'>I care about the details and consider the motion design of affordances to be crucial in creating effective, intuitive interfaces. Passionate about user research and using data to solve problems. I like prototyping - it helps me think through interaction models and new possibilities.</p>

                    </div>
                </div>

                {/* <div className='photographyMainContainer'>
                    <h3 className='photographyMainHeading'>Photography</h3>
                    <p className='photographyMainParagraph'>I’m also incredibly inspired by photography. It helps me recover from when I feel creatively stunted.</p>
                    <br></br>
                    <div className='aboutImagesContainer'>
                        <img src={one} className='aboutImage' alt='Ali' />
                        <img src={two} className='aboutImage' alt='Ali' />
                        <img src={three} className='aboutImage' alt='Ali' />
                    </div>
                    <div className='aboutImagesContainer'>
                        <img src={four} className='aboutImage' alt='Ali' />
                        <img src={five} className='aboutImage' alt='Ali' />
                        <img src={six} className='aboutImage' alt='Ali' />
                    </div>
                    <br></br>
                </div> */}

            </div>

            <div className='aboutSectionInsights'>

                <div className='betfairSectionProblemChallange'>
                    <h3 className='aboutSectionScopeMainHeading'>This is how I do it</h3>
                    <p className='betfairSectionScopeParagraph'>Putting users first in all aspects in the design process to create a meaningful and memorable experience.</p>
                </div>
                <div className='neuroMobileSectionInsightsMainContainer'>
                    <div className='neuroMobileSectionInsightsDivsContainer'>
                        <div className='aboutSectionInsightsSingleDiv'>
                            <img src={resultsPic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='blackHeadingAbout'>Empathy is key</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs'>I ask all sorts of questions to make decisions about how things will play out from a user perspective before jumping into designing.</p>
                        </div>
                        <div className='aboutSectionInsightsSingleDiv'>
                            <img src={sharingPic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='blackHeadingAbout'>Less is better</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs'>By eliminating unnecessary features or UI elements, I strive to make the product more useful, consistent and enjoyable for users.</p>
                        </div>
                        <div className='aboutSectionInsightsSingleDiv'>
                            <img src={securityPic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='blackHeadingAbout'>Communicate with engineers</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs'>Iteration is inevitable but it doesn't have to be a struggle when we both are willing to pivot and talk it through.</p>
                        </div>

                    </div>
                </div>
            </div>

            <div className='betfairSectionOverviewMain'>
                <div id="apartFromWork" className='mainClassApartFromWork'>
                <br></br>
                <br></br>
                <h3 className='apartFromWorkText' >Apart from work, I write and host podcasts. I create content & videos.</h3>
            <br></br>
            <br></br>
                    <div className='apartFromWorkPicsContainer' >
                        <a href="https://www.amazon.com.au/Heartache-healing-Jay-Win-ebook/dp/B094NXGYLM" target="_blank"><img src={book} className='apartFromWorkPics' alt='Ali' /></a>
                        <div className='latestWorkEmptyContainer'></div>
                        <div className='latestWorkEmptyHorizontalContainer'></div>
                        <a href="https://open.spotify.com/show/3IULIN2pblCHIswgKcZSJj" target="_blank"><img src={pod} className='apartFromWorkPics' alt='Ali' /></a>
                    </div>

                </div>
            </div>

            {/* <SectionFooter /> */}
        </div>
    )
}
