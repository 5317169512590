import React, { useState } from "react";
import mobilePic1 from './images/mobilePic1.png';
import tabPic1 from './images/tabPic1.png';
import aero from './images/aero.png';
import laptop from './images/laptop.png';
import twoMobiles from './images/twoMobiles.png';
import betfair from '../betfair/images/bg2.png';
import racv from '../racv/images/bg2.png';
import bagation from '../bagation/images/bg2.png';
import valiant from '../valiant/images/bgMobile.png';
import lock from './images/lock.png';
import './sectionLatestWork.modules.css';
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";

export default () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [password, setPassword] = useState("");
    const [link, setLink] = useState("");
    const onLoginFormSubmit = (e) => {
        e.preventDefault();
        if (password == 'jay@12345') {
            window.location.href = link;
        }
        handleClose();
    };
    return (
        <div id="latestWork" className='mainClassLatestWork'>

            <div className='latestWorkMainContainer'>
                <div>
                    {/* <div className='latestWorkEmptyHorizontalContainer'></div> */}
                    <div className='latestWorkPicContainer'
                        onClick={() => { setLink("/valiant"); setShow(true) }}
                    //onClick={() => { window.location.href = "/neuro-mobile"; }}
                    >

                        <div className='latestWorkInsideContainerText'>
                            <h2 className='latestWorkAppHeading'>Valiant Finance</h2>
                            <p className='latestWorkAppParagraph'>Australia's trusted business loan marketplace. Discover your loan options in minutes with lender matches tailored to your business needs.</p>
                            <div className='latestWorkAppAeroText'>
                                <h4 className='latestWorkAppEnterPassword'  >Read case study</h4>
                                <img src={aero} className='aero' alt='Ali' />
                            </div>
                        </div>

                        <img src={valiant} className='mobilePic1' alt='Ali' />
                    </div>
                    <div
                        //onClick={() => { window.location.href = "/neuro-mobile"; }}
                        onClick={() => { setLink("/valiant"); setShow(true) }}
                    >
                        {/* <h2 className='latestWorkAppHeading'>Betfair - Australia</h2>
                        <p className='latestWorkAppParagraph'>Lorem ipsum dolor sit amet consectetur. Dolor sem cursus in vel proin lacus tortor.</p>
                        <div className='latestWorkAppAeroText'>
                            <h4 className='latestWorkAppEnterPassword'  >Enter Password </h4>
                            <img src={aero} className='aero' alt='Ali' />
                        </div> */}
                        <br></br>
                        <br></br>
                        {/* <div className='latestWorkAppAeroText'>
                            <h4 className='latestWorkAppEnterPassword'>View Case Study </h4>
                            <img src={aero} className='aero' alt='Ali' />
                        </div> */}
                    </div>

                    <div className='latestWorkPicContainer3'
                        onClick={() => { setLink("/racv"); setShow(true) }}
                    >
                        <div className='latestWorkInsideContainerText'>
                            <h2 className='latestWorkAppHeading'>RACV</h2>
                            <p className='latestWorkAppParagraph'>The Royal Automobile Club of Victoria is a motoring club and mutual organisation.</p>
                            <div className='latestWorkAppAeroText'>
                                <h4 className='latestWorkAppEnterPassword'  >Read case study</h4>
                                <img src={aero} className='aero' alt='Ali' />
                            </div>
                        </div>
                        <img src={racv} className='laptopPic1' alt='Ali' />
                    </div>

                    {/* <div
                        //onClick={() => { window.location.href = "/neighbourhood-mobile"; }}
                        onClick={() => { setLink("/neighbourhood-mobile"); setShow(true) }}
                    >
                        <h2 className='latestWorkAppHeading'>Bagation</h2>
                        <p className='latestWorkAppParagraph'>Lorem ipsum dolor sit amet consectetur. Dolor sem cursus in vel proin lacus tortor.</p>
                        <div className='latestWorkAppAeroText'>
                            <h4 className='latestWorkAppEnterPassword'  >Enter Password </h4>
                            <img src={aero} className='aero' alt='Ali' />
                        </div>
                    </div> */}
                </div>

                <div className='latestWorkEmptyContainer'></div>
                <div className='latestWorkEmptyHorizontalContainer'></div>
                <div>
                    <div className='latestWorkPicContainer2'
                        //onClick={() => { window.location.href = "/neuro-tab"; }}
                        onClick={() => { setLink("/betfair"); setShow(true) }}
                    >
                        <div className='latestWorkInsideContainerText'>
                            <h2 className='latestWorkAppHeading'>Betfair - Australia</h2>
                            <p className='latestWorkAppParagraph'>Betfair Australia is the only betting exchange in operation in Australia. A betting exchange is a marketplace for customers to bet on the outcome of discrete events. </p>
                            <div className='latestWorkAppAeroText'>
                                <h4 className='latestWorkAppEnterPassword' onClick={() => { setLink("/neuro-mobile"); setShow(true) }}>Read case study</h4>
                                <img src={aero} className='aero' alt='Ali' />
                            </div>
                        </div>
                        <img src={betfair} className='laptopPic1' alt='Ali' />
                    </div>
                    <div
                        //onClick={() => { window.location.href = "/neuro-tab"; }}
                        onClick={() => { setLink("/betfair"); setShow(true) }}
                    >
                        {/* <h2 className='latestWorkAppHeading'>Valiant Finance</h2>
                        <p className='latestWorkAppParagraph'>Lorem ipsum dolor sit amet consectetur. Dolor sem cursus in vel proin lacus tortor.</p>
                        <div className='latestWorkAppAeroText'>
                            <h4 className='latestWorkAppEnterPassword' onClick={() => { setLink("/neuro-mobile"); setShow(true) }}>Enter Password </h4>
                            <img src={aero} className='aero' alt='Ali' />
                        </div> */}
                                                <br></br>
                        <br></br>
                        {/* <div className='latestWorkAppAeroText'>
                            <h4 className='latestWorkAppEnterPassword'>View Case Study </h4>
                            <img src={aero} className='aero' alt='Ali' />
                        </div> */}
                    </div>


                    <div className='latestWorkPicContainer4'
                        onClick={() => { setLink("/bagation"); setShow(true) }}
                    >
                        <div className='latestWorkInsideContainerText'>
                            <h2 className='latestWorkAppHeading'>Bagation</h2>
                            <p className='latestWorkAppParagraph'>Bagation is a luggage storage marketplace it helps the travellers to connect with the nearest connivence store to store their bags after they check out of their stays. </p>
                            <div className='latestWorkAppAeroText'>
                                <h4 className='latestWorkAppEnterPassword'  >Read case study</h4>
                                <img src={aero} className='aero' alt='Ali' />
                            </div>
                        </div>
                        <img src={bagation} className='laptopPic1' alt='Ali' />
                    </div>


                    {/* <div
                        //onClick={() => { window.location.href = "/project-ryba"; }}
                        onClick={() => { setLink("/project-ryba"); setShow(true) }}
                    >
                        <h2 className='latestWorkAppHeading'>RACV</h2>
                        <p className='latestWorkAppParagraph'>Lorem ipsum dolor sit amet consectetur. Dolor sem cursus in vel proin lacus tortor.</p>
                        <div className='latestWorkAppAeroText'>
                            <h4 className='latestWorkAppEnterPassword'  >Enter Password </h4>
                            <img src={aero} className='aero' alt='Ali' />
                        </div>
                    </div> */}
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                {/* <Modal.Header closeButton>
                    <Modal.Title>Login Form</Modal.Title>
                </Modal.Header> */}
                <Modal.Body className='modalMain'>
                    <br></br>
                    <br></br>
                    <img src={lock} className='modalLock' alt='lock' />
                    <br></br>
                    <br></br>
                    <h2 className='modalContentProtected'>This content is protected.</h2>
                    <p className='toView'>To view, please enter the password.</p>
                    <br></br>


                    <Form onSubmit={onLoginFormSubmit}>
                        <Form.Control
                            type="password"
                            placeholder="Enter Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className='spaceInModalContent'></div>

                        <Button variant="primary" type="submit" block>
                            Submit
                        </Button>
                        <br></br>
                    </Form>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close Modal</Button>
                </Modal.Footer> */}
            </Modal>

        </div>
    )
}