import React from 'react';
import './racv.modules.css';
import bg1 from './images/bg1.png';
import bg2 from './images/bg2.png';
import bgM from './images/bgMobile.png';
import betfairLogo from './images/betfairLogo.png';
import threeButtons from './images/threeButtons.svg';
import overview from './images/overview.svg';
import problem from './images/problem.svg';
import solution from './images/solution.svg';
import scopeSVG from './images/scope.svg';
import scope1 from './images/scope1.svg';
import scope2 from './images/scope2.svg';
import scope3 from './images/scope3.svg';
import scope4 from './images/scope4.svg';
import video1 from "./videos/1.mov";
import video2 from "./videos/2.mov";
import video3 from "./videos/3.mov";
import video4 from "./videos/4.mov";
import video5 from "./videos/5.mov";
import video6 from "./videos/6.mov";
import video7 from "./videos/7.mov";
import video8 from "./videos/8.mov";
import video9 from "./videos/9.mov";
import LongTermGoal from './images/ltGoal.svg';
import accountant from './images/Accountant.svg';
import builder from './images/builder.svg';
import professional from './images/professional.svg';
import userFlows from './images/userFlows.svg';
import broker from './images/broker.svg';
import infoSVG from './images/info.svg';
import ltg1 from './images/ltg1.png';
import design1 from './images/design1.png';
import design2 from './images/design2.png';
import s1 from './images/s1.png';
import s2 from './images/s2.png';
import s3 from './images/s3.png';
import s4 from './images/s4.png';
import s5 from './images/s5.png';
import s6 from './images/s6.png';
import s7 from './images/s7.png';
import s8 from './images/s8.png';
import s9 from './images/s9.png';
import key from './images/key.svg';
import book from '../sectionApartFromWork/images/book.svg';
import pod from '../sectionApartFromWork/images/pod.svg';

import reactPic from './images/reactPic.PNG';
import researchPic from './images/researchPic.png';
import competitiorPic from './images/competitiorPic.png';
import resultsPic from './images/resultsPic.svg';
import sharingPic from './images/sharingPic.svg';
import securityPic from './images/securityPic.svg';
import personPic from './images/person.svg';
import scopePic from './images/scopePic.png';
import conceptualizationPic from './images/conceptualization.png';
import moodPic from './images/mood.png';
import brandPic from './images/brand.png';
import twoMobiles from './../sectionLatestWork/images/twoMobiles.png';
import laptop from './../sectionLatestWork/images/laptop.png';
import tabPic1 from './../sectionLatestWork/images/tabPic1.png';
import leftAero from './images/leftAero.svg';
import SectionTopBar from '../sectionTopBar/sectionTopBar';
import SectionFooter from '../sectionFooter/sectionFooter';

export default () => {
    return (
        <div className='mainClassNeuroMobileWebsite'>
            <SectionTopBar styleColor="#FFFFFF" />
            {/* <div className='backAeroContainer'>
                <a className='backAeroContainerSub' href="javascript:history.back()"><img src={leftAero} className='leftAero' alt='Ali' /></a>
            </div> */}

            {/* <div className='mainNeuroMobileContainer'>
                <h3 className='neuroMobileAppHeading'>betfair</h3>
                <p className='neuroMobileAppParagraph'>A single app to get update about your and yours loved one’s mental health.</p>

                <img src={threeMobiles} className='threeMobiles' alt='Ali' />

            </div> */}

            <div className='mainClassRACVHero'>
            <div className='subClassBetfairHero'>
                    <div className='leftClassBetfairHero'>

                        <div className='innerleftClassBetfairHero'>

                            <div className='backAeroContainerBetfairHero'>
                                <a className='backAeroContainerBetfairHeroSub' href="javascript:history.back()"><img src={leftAero} className='leftAero' alt='Ali' /></a>
                            </div>

                            <img src={betfairLogo} className='LogoRACV' alt='Ali' />

                            <h1 className='racvHeroMainHeading'>The Royal Automobile Club of Victoria is a motoring club and mutual organisation.</h1>

                            <h1 className='racvHeroSubHeading'>Offering various services to members, including insurance and roadside assistance. </h1>
                            <img src={threeButtons} className='threeButtons' alt='Ali' />
                        </div>

                    </div>

                    <div className='heroMainImageBackgroundBetfair'>
                        <img src={bg2} className='imageHeroBetfair' alt='Ali' />
                    </div>
                </div>

            </div>

            {/*Section Overview Problem Solution  */}
            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <div className='betfairSectionProblemChallangeDivOne'>
                        <img src={overview} className='ProblemChallangeIcons' alt='Ali' />
                        <p className='betfairSectionProblemChallangeParagraph'>The Royal Automobile Club of Victoria (RACV) Ltd was founded in 1903 and is an unlisted public company. RACV (www.racv.com.au) is Victoria’s largest mutual organisation, employing about 2600 staff across more than 50 locations.</p>
                    </div>
                    <div className='betfairSectionProblemChallangeDivOne'>
                        <img src={solution} className='ProblemChallangeIcons' alt='Ali' />
                        <p className='betfairSectionProblemChallangeParagraph'>The problem was RACV wants to bring in more members by creating a new offer where ERA members can invite their friends or family members to their plan. There are 3 plans ERA offers. They are Basic care, Extra Care and Total Care. Total care being the most prestigious plan as compared to the other two. Based on which plan members are on, friends and family members of particular plan owners, will have access to particular dashboard. Needed to redefine the functionality of the new dashboards for each plan owners and the added members. </p>
                    </div>
                    <div className='betfairSectionProblemChallangeDivOne'>
                        <img src={problem} className='ProblemChallangeIcons' alt='Ali' />
                        <p className='racvSectionProblemChallangeParagraph'>The business objective is to raise standards of practice and service in the general insurance industry. </p>
                    </div>
                </div>
            </div>


            {/* <div className='neuroMobileSectionOverview'>
                <div className='neuroMobileSectionOverviewDivOne'>
                    <h4 className='neuroMobileSectionOverviewHeading'>OVERVIEW</h4>
                    <br></br>
                    <p className='neuroMobileSectionOverviewParagraphs'><span className='neuroMobileSectionOverviewDivOneReact'>React Neuro</span> is a digital platform designed to change the way we engage with our brain health.
                        Building on decades of breakthrough research, we use Virtual Reality to probe deeply into the
                        neural circuits that make us tick.</p>

                    <p className='neuroMobileSectionOverviewParagraphs'>The aim of this project was to <span className='neuroMobileSectionUsersProfilesParagraphsBold' >solve the problem to get updates
                        regarding reports, trends and learnings</span> for React’s patients
                        through an app.</p>
                    <br></br>
                    <h5 className='neuroMobileSectionOverviewHeading'>CLIENT</h5>
                    <img src={reactPic} className='reactLogoNeuroMobile' alt='React Logo' />

                </div>
                <div className='neuroMobileSectionOverviewDivTwo'>
                    <h4 className='neuroMobileSectionOverviewHeading'>ROLE & DURATION</h4>
                    <br></br>
                    <h5 className='neuroMobileSectionUsersProfilesParagraphCustom' >UX Designer | VentureDive</h5>
                    <p className='neuroMobileSectionOverviewCustomParagraph'>User Research, Visual Design, Interaction, Prototyping.</p>
                    <p className='neuroMobileSectionOverviewParagraphs'>Nov 2021 - Feb 2022</p>
                </div>
            </div> */}

            {/* <div className='neuroMobileSectionTheProblem'>
                <div className='neuroMobileSectionTheProblemMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>The Problem</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Poor communication between the operator and patient can become a barrier to an organization’s success.</p>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>For operator managing multiple tasks can be difficult, especially when you have to
                        manage a large number of patients at a same time. At this moment there is lack of proper
                        guidence and communication regarding the reports of patients.</p>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Also whenever patients need to check their reports they have to visit react centre everytime.</p>
                </div>
            </div> */}

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>Scope of Work and Timeline</h3>
                    <p className='betfairSectionScopeParagraph'>All the activities involved in this project displayed over a 04 months timeline</p>

                    <div className='betfairSectionScope'>
                        <img src={scope1} className='ScopeIcons' alt='Ali' />
                        <img src={scope2} className='ScopeIcons' alt='Ali' />
                        <img src={scope3} className='ScopeIcons' alt='Ali' />
                        <img src={scope4} className='ScopeIcons' alt='Ali' />
                    </div>

                    {/* <img src={scopeSVG} className='researchPicNeuroMobile' alt='React Logo' /> */}
                </div>
            </div>

            <div className='neuroMobileSectionInsights'>

                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>Design Principles</h3>
                    <p className='betfairSectionScopeParagraph'>Defining the top 5-6 design principles for the RACV platform</p>
                </div>

                <div className='neuroMobileSectionInsightsMainContainer'>
                    <div className='rybaSectionInsightsDivsContainer'>
                        <div className='rybaSectiondesignPrincipalsdiv'>
                            <div className='racvSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Simple </p>
                            </div>
                            <div className='racvSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Human </p>
                            </div>


                            <div className='racvSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Activates Gratitude </p>
                            </div>
                        </div>
                        <div className='rybaSectiondesignPrincipalsdiv'>
                            <div className='racvSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Social </p>
                            </div>


                            <div className='racvSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Instant </p>
                            </div>
                            <div className='racvSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Accessible </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



            {/* <div className='neuroMobileSectionResearch'>
                <div className='rybaSectionScopeMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Scope of Work & Timeline</h3>
                    <p className='neuroMobileSectionConceptualizationParagraph'>We defined all the scope of work and
                        decided all the timelines before the project started there may be a little bit change of timeline
                        on the basis of priority work. All the activities involved in this project displayed over a 04
                        months timeline.</p>
                    <img src={scopePic} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div> */}
            

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>RACV’s Design System</h3>
                    <p className='betfairSectionScopeParagraph'>Defining a basic foundations of brand identify for the RACV</p>
                    <br></br>
                    <div className='valiantSectionHomeImgMain'>
                        <img src={design1} className='rybaSectionHomeImg1' alt='React Logo' />
                        <br></br>
                        <img src={design2} className='rybaSectionHomeMobileImg1' alt='React Logo' />
                    </div>
                </div>
            </div>

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>High-Fidelity Designs</h3>
                    <p className='betfairSectionScopeParagraph'>We divided our design tasks into scenario and design on the basis of multiple scenarios.</p>
                    <br></br>
                    <h3 className='racvSectionScopeMainHeading'>MOA Dashboard (Driver & Plan Owner)</h3>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Scenario 1</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>User is an invited driver on someone else's Multi ERA plan and accepts the invitation</p>
                        </div>
                        {/* <img src={s1} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video1} autoPlay={"autoplay"} loop controls="controls" />
                    </div>
                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        {/* <img src={s2} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video2} autoPlay={"autoplay"} loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Scenario 2</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>User has a single ERA plan, and is an invited driver on someone else's Multi ERA plan & accepts it (there is no overlap of vehicles)</p>
                        </div>
                    </div>
                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Scenario 3</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>User has a Multi ERA plan, and is an invited driver on someone else's Multi ERA plan (there is no overlap of vehicles)</p>
                        </div>
                        {/* <img src={s3} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video3} autoPlay={"autoplay"} loop controls="controls" />
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>

                    <h3 className='racvSectionScopeMainHeading'>ERA management screen (Driver)</h3>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Scenario 1</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>Driver views detail of the plan they are invited to and accepts</p>
                        </div>
                        {/* <img src={s4} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video4} autoPlay={"autoplay"} loop controls="controls" />
                    </div>
                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        {/* <img src={s5} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video5} autoPlay={"autoplay"} loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Scenario 2</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>User takes a look at product detail before Declining the invite that has a double subscription on a vehicle</p>
                        </div>
                    </div>
                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Scenario 3</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>Driver leaves a multi ERA plan they were invited to</p>
                        </div>
                        {/* <img src={s6} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video6} autoPlay={"autoplay"} loop controls="controls" />
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>


                    <h3 className='racvSectionScopeMainHeading'>Multi ERA invitations</h3>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Scenario 1</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>Existing RACV Member’s without a logged in account, logs in and accepts</p>
                        </div>
                        {/* <img src={s7} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video7} autoPlay={"autoplay"} loop controls="controls" />
                    </div>
                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        {/* <img src={s8} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video8} autoPlay={"autoplay"} loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Scenario 2</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>User takes a look at product detail before Declining the invite that has a double subscription on a vehicle</p>
                        </div>
                    </div>
                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Scenario 3</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>Driver leaves a multi ERA plan they were invited to</p>
                        </div>
                        {/* <img src={s9} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video9} autoPlay={"autoplay"} loop controls="controls" />
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>

                    <h3 className='betfairSectionScopeMainHeading'>Conclusion</h3>
                    <p className='betfairSectionScopeParagraph'>Summarizing the experience, learnings and future plan</p>
                    <br></br>

                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <img src={key} className='racvSectionTheSolutionVideo1' alt='React Logo' />
                        <div className='neuroMobileSectionTheSolutionSubDiv1'>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>Working with RACV was a great learning experience. We followed our pre-defined design process to create a solution backed by research and validation. We were able to gather interesting insights from the stakeholders and the end users which helped shape the solution for the better.</p>
                        </div>
                    </div>

                </div>
            </div>
            {/* <br></br> */}

            {/* <div className='betfairSectionOverviewMain'>
                <div id="apartFromWork" className='mainClassApartFromWork'>
                    <h3 className='apartFromWorkText1' >Want to explore more, here are options</h3>
                    <br></br>
                    <div className='apartFromWorkPicsContainer' >
                        <img src={book} className='apartFromWorkPics' alt='Ali' />
                        <div className='latestWorkEmptyContainer'></div>
                        <div className='latestWorkEmptyHorizontalContainer'></div>
                        <img src={pod} className='apartFromWorkPics' alt='Ali' />
                    </div>

                </div>
            </div> */}

            {/* <div className='neuroMobileSpacer'></div> */}
            {/* <SectionFooter /> */}
        </div>
    )
}
