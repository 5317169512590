import React from 'react';

export default () => {
    return (
        <div>
            <h2>Services Page</h2>
            <p>Lorem ipsum goes here</p>
        </div>
    )
}
