import React, { useState } from "react";
import './newLaunch.modules.css';
import bg2 from './rocket.png';
import bgM from './rocket.png';
import SectionTopBar from '../sectionTopBar/sectionTopBar';
import leftAero from './cityLogo.svg';
import Container from 'react-bootstrap/Container'

export default () => {
    const [email, setEmail] = useState("");

    
    function callGetAPI() {
        
    let previousEmails = "";
        
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
    };

    fetch("https://getpantry.cloud/apiv1/pantry/78c5cff1-3973-48d0-bb53-86742ae8b407/basket/subscribers", requestOptions)
    .then(response => response.text())
    .then(result => {
        console.log(result)
        previousEmails = JSON.parse(result);
        console.log("-------- Previous Emails --------------");
        console.log(previousEmails.emails);


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        var raw = JSON.stringify({
            "emails":previousEmails.emails + email + ","
        });
    
        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        
        fetch("https://getpantry.cloud/apiv1/pantry/78c5cff1-3973-48d0-bb53-86742ae8b407/basket/subscribers", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

    }
    )
    .catch(error => console.log('error', error));

    } 
      


    function handleChange(e) {
        setEmail(e.target.value);
    }
    function handleSubmit(e) {

        console.log("----------------------");
        console.log(email);
        console.log("------ Calling API ----------------");
        callGetAPI();
        console.log("--------- DONE -------------");
        setEmail("");

        e.preventDefault();
      }

    return (
        <div className='mainClassAboutWebsite'>

            <SectionTopBar page="new" />

            <div className='mainClassNewLaunchHero'>
                <div className='subClassBetfairHero'>
                    <div className='leftClassBetfairHero'>

                        <div className='innerleftClassBetfairHero'>
                            {/* <div className='backAeroContainerBetfairHero'> */}
                                <img src={leftAero} className='citySwapLogo' alt='Ali' />
                            {/* </div> */}
                            <h1 className='newLaunchMainHeading'>All Good Things Come to Those who Wait...</h1>
                            <h1 className='newLaunchSubHeading'>Get notified when we launch.</h1>

                        <form onSubmit={handleSubmit} className='subscribeForm'>
                                <label>
                                    <input className='subscribeInput' type="text" name="name" value={email} onChange={handleChange} placeholder='Email' />
                                </label>
                                <input className='subscribe' type="submit" value="Subscribe" />
                                </form>

                        </div>

                    </div>

                    <div className='heroMainImageBackgroundBetfair'>
                        <img src={bg2} className='imageHeroValiant' alt='Ali' />
                        <img src={bgM} className='imageHeroValiantMobile' alt='Ali' />
                    </div>
                </div>

            </div>
            

            {/* <SectionFooter /> */}
        </div>
    )
}
