import React from 'react';
import topbarImage from './images/image.svg';
import dropdownIcon from './images/dropdownIcon.png';
import resume from './images/hashirResume.pdf';
import './sectionTopBar.modules.css';

export default (props) => {
    const bgColor = props.styleColor ? props.styleColor : "none";
    const page = props.page ? props.page : "none";
    return (
        <div className='mainClassTopBarA' style={{ background: bgColor, }}>
             <div className='subClassTopBar'>

                <a href='/'><img src={topbarImage} className='topbarImageA' alt='Jay' /></a>
                <div className='topbarRightContainerA'>
                    <a href='/' className='textDecorationA' ><p className='helpTextA' style={{ color: page == "home" ? "#7265E3" : "black", fontWeight: page == "home" ? 700 : 500 }}>Home </p></a>
                    <a href='/about' className='textDecorationA' ><p className='helpTextA' style={{ color: page == "about" ? "#7265E3" : "black", fontWeight: page == "about" ? 700 : 500 }} >About Me</p></a>
                    <a href="mailto:smonwin87@gmail.com" className='textDecorationA' ><p className='helpTextA' >Email Me </p></a>
                    {/* <a href='/new' className='textDecorationA' ><p className='helpTextA' style={{ color: page == "new" ? "#7265E3" : "black", fontWeight: page == "new" ? 700 : 500 }} >New</p></a> */}
                    {/* <a href={resume} target="_blank" className='textDecorationA' ><p className='helpTextA'>Podcast </p></a> */}
                    {/* <a href='/' target="_blank" className='textDecorationA' ><p className='helpTextA'>Podcast </p></a> */}
                </div>
            </div>
            {/*
            <div class="dropdown">
                <img src={dropdownIcon} className='dropbtn' alt='Dropdown Icon' />
                <div class="dropdown-contentA">
                    <br></br><br></br>
                    <a href="/" style={{ color: page == "home" ? "#7265E3" : "black", fontWeight: page == "home" ? 600 : 400 }}>Home</a>
                    <a href="/about" style={{ color: page == "about" ? "#7265E3" : "black", fontWeight: page == "about" ? 600 : 400 }}>About</a>
                    <a href="#education">Experiments</a>
                    <a href={resume} target="_blank">Resume</a>
                </div>
            </div> */}


            {/* New code from internet for dropdown  ONLY for MOBILES*/}
            <link href="http://fonts.googleapis.com/css?family=Droid+Serif" rel="stylesheet" type="text/css" />
            <header class="header"  style={{ background: page == "home" ? "#fff" : "#fff" }}>
                <a href='/'><img src={topbarImage}  className='topbarImageA' alt='HK' /></a>
                <input class="menu-btn" type="checkbox" id="menu-btn" />
                <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
                <ul class="menu">
                    <li><a href="/" style={{ color: page == "home" ? "#7265E3" : "#212529", fontWeight: page == "home" ? 600 : 600, fontSize: 32 }}>Home</a></li>
                    <li><a href="/about" style={{ color: page == "about" ? "#7265E3" : "#212529", fontWeight: page == "about" ? 600 : 600, fontSize: 32 }}>About Me</a></li>
                    <li><a href="mailto:smonwin87@gmail.com" style={{ color: "#212529", fontSize: 32, fontWeight: 600 }} >Email Me</a></li>
                    {/* <li><a href="/new" style={{ color: page == "new" ? "#7265E3" : "#212529", fontWeight: page == "new" ? 600 : 600, fontSize: 32 }}>New</a></li> */}
                    {/* <li><a href={resume} target="_blank" style={{ color: "#212529", fontSize: 32, fontWeight: 600 }}>Podcast</a></li> */}
                    {/* <li><a href="/" target="_blank" style={{ color: "#212529", fontSize: 32, fontWeight: 600 }}>Podcast</a></li> */}
                </ul>
            </header>



        </div>
    )
}