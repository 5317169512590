import React from 'react';
import { Route, Switch, Routes }  from 'react-router-dom';
import Home from './components/home/';
import Services from './components/services/';
import Test from './components/test/';
import NeuroMobile from './components/neuroMobile/';
import NeuroTab from './components/neuroTab/';
import NeighbourMobile from './components/neighbourMobile/';
import ProjectRyba from './components/projectRyba/';
import Betfair from './components/betfair/';
import Valiant from './components/valiant/';
import Racv from './components/racv/';
import Bagation from './components/bagation/';
import Contact from './components/contact/';
import About from './components/about/';
import NewLaunch from './components/newLaunch/';
import NotFound from './components/404';

export default () => (
    <Switch>
        <Route path="/services" component={Services} />
        <Route path="/test" component={Test} />
        <Route path="/neuro-mobile" component={NeuroMobile} />
        <Route path="/betfair" component={Betfair} />
        <Route path="/valiant" component={Valiant} />
        <Route path="/racv" component={Racv} />
        <Route path="/bagation" component={Bagation} />
        <Route path="/neuro-tab" component={NeuroTab} />
        <Route path="/neighbourhood-mobile" component={NeighbourMobile} />
        <Route path="/project-ryba" component={ProjectRyba} />
        <Route path="/contact" component={Contact} />
        <Route path="/about" component={About} />
        <Route path="/new1" component={NewLaunch} />
        <Route exact path="/" component={Home} />
        <Route component={NotFound} />
    </Switch>
);
