import React from 'react';
import './bagation.modules.css';
import bg1 from './images/bg1.png';
import bg2 from './images/bg2.png';
import bgM from './images/bgMobile.png';
import betfairLogo from './images/betfairLogo.svg';
import threeButtons from './images/threeButtons.svg';
import overview from './images/overview.svg';
import problem from './images/problem.svg';
import solution from './images/solution.svg';
import scope1 from './images/scope1.svg';
import scope2 from './images/scope2.svg';
import scope3 from './images/scope3.svg';
import scope4 from './images/scope4.svg';
import video1 from "./videos/1.mov";
import video2 from "./videos/2.mov";
import video3 from "./videos/3.mov";
import video4 from "./videos/4.mov";
import LongTermGoal from './images/ltGoal.svg';
import accountant from './images/Accountant.svg';
import builder from './images/builder.svg';
import professional from './images/professional.svg';
import userFlows from './images/userFlows.svg';
import broker from './images/broker.svg';
import infoSVG from './images/info.svg';
import ltg1 from './images/ltg1.png';
import styleGuide from './images/styleGuide.png';
import s1 from './images/s1.png';
import s2 from './images/s2.png';
import s3 from './images/s3.png';
import s4 from './images/s4.png';
import key from './images/key.svg';
import book from '../sectionApartFromWork/images/book.svg';
import pod from '../sectionApartFromWork/images/pod.svg';

import reactPic from './images/reactPic.PNG';
import researchPic from './images/researchPic.png';
import competitiorPic from './images/competitiorPic.png';
import resultsPic from './images/resultsPic.svg';
import sharingPic from './images/sharingPic.svg';
import securityPic from './images/securityPic.svg';
import personPic from './images/person.svg';
import scopePic from './images/scopePic.png';
import conceptualizationPic from './images/conceptualization.png';
import moodPic from './images/mood.png';
import brandPic from './images/brand.png';
import twoMobiles from './../sectionLatestWork/images/twoMobiles.png';
import laptop from './../sectionLatestWork/images/laptop.png';
import tabPic1 from './../sectionLatestWork/images/tabPic1.png';
import leftAero from './images/leftAero.svg';
import SectionTopBar from '../sectionTopBar/sectionTopBar';
import SectionFooter from '../sectionFooter/sectionFooter';

export default () => {
    return (
        <div className='mainClassNeuroMobileWebsite'>
            <SectionTopBar styleColor="#FFFFFF" />
            {/* <div className='backAeroContainer'>
                <a className='backAeroContainerSub' href="javascript:history.back()"><img src={leftAero} className='leftAero' alt='Ali' /></a>
            </div> */}

            {/* <div className='mainNeuroMobileContainer'>
                <h3 className='neuroMobileAppHeading'>betfair</h3>
                <p className='neuroMobileAppParagraph'>A single app to get update about your and yours loved one’s mental health.</p>

                <img src={threeMobiles} className='threeMobiles' alt='Ali' />

            </div> */}

            <div className='mainClassBagationHero'>
            <div className='subClassBetfairHero'>
                    <div className='leftClassBetfairHero'>

                        <div className='innerleftClassBetfairHero'>

                            <div className='backAeroContainerBetfairHero'>
                                <a className='backAeroContainerBetfairHeroSub' href="javascript:history.back()"><img src={leftAero} className='leftAero' alt='Ali' /></a>
                            </div>

                            <img src={betfairLogo} className='LogoRACV' alt='Ali' />

                            <h1 className='racvHeroMainHeading'>Bagation is a luggage storage platfom that lets travellers find and leave their luggage at the nearest secure place. </h1>

                            {/* <h1 className='racvHeroSubHeading'>Offering various services to members, including insurance and roadside assistance. </h1> */}
                            <img src={threeButtons} className='threeButtons' alt='Ali' />
                        </div>

                    </div>

                    <div className='heroMainImageBackgroundBetfair'>
                        <img src={bg2} className='imageHeroBetfair' alt='Ali' />
                    </div>
                </div>

            </div>

            {/*Section Overview Problem Solution  */}
            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <div className='betfairSectionProblemChallangeDivOne'>
                        <img src={overview} className='ProblemChallangeIcons' alt='Ali' />
                        <p className='betfairSectionProblemChallangeParagraph'>Bagation is a luggage storage platform that lets travellers find and leave their luggage at the nearest secure place. </p>
                    </div>
                    <div className='betfairSectionProblemChallangeDivOne'>
                        <img src={solution} className='ProblemChallangeIcons' alt='Ali' />
                        <p className='betfairSectionProblemChallangeParagraph'>Many travellers face the inconvenience of having to carry their luggage with them during the day while exploring a new city or waiting for their next mode of transportation. This can be tiring and limit their ability to fully enjoy their travels. Additionally, travellers may face the risk of theft or loss of their luggage when leaving it unattended in public places.</p>
                    </div>
                    <div className='betfairSectionProblemChallangeDivOne'>
                        <img src={problem} className='ProblemChallangeIcons' alt='Ali' />
                        <p className='racvSectionProblemChallangeParagraph'>The business objective is to partner up with as many small businesses as possible to provide luggage storage solutions to the travellers. </p>
                    </div>
                </div>
            </div>


            {/* <div className='neuroMobileSectionOverview'>
                <div className='neuroMobileSectionOverviewDivOne'>
                    <h4 className='neuroMobileSectionOverviewHeading'>OVERVIEW</h4>
                    <br></br>
                    <p className='neuroMobileSectionOverviewParagraphs'><span className='neuroMobileSectionOverviewDivOneReact'>React Neuro</span> is a digital platform designed to change the way we engage with our brain health.
                        Building on decades of breakthrough research, we use Virtual Reality to probe deeply into the
                        neural circuits that make us tick.</p>

                    <p className='neuroMobileSectionOverviewParagraphs'>The aim of this project was to <span className='neuroMobileSectionUsersProfilesParagraphsBold' >solve the problem to get updates
                        regarding reports, trends and learnings</span> for React’s patients
                        through an app.</p>
                    <br></br>
                    <h5 className='neuroMobileSectionOverviewHeading'>CLIENT</h5>
                    <img src={reactPic} className='reactLogoNeuroMobile' alt='React Logo' />

                </div>
                <div className='neuroMobileSectionOverviewDivTwo'>
                    <h4 className='neuroMobileSectionOverviewHeading'>ROLE & DURATION</h4>
                    <br></br>
                    <h5 className='neuroMobileSectionUsersProfilesParagraphCustom' >UX Designer | VentureDive</h5>
                    <p className='neuroMobileSectionOverviewCustomParagraph'>User Research, Visual Design, Interaction, Prototyping.</p>
                    <p className='neuroMobileSectionOverviewParagraphs'>Nov 2021 - Feb 2022</p>
                </div>
            </div> */}

            {/* <div className='neuroMobileSectionTheProblem'>
                <div className='neuroMobileSectionTheProblemMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>The Problem</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Poor communication between the operator and patient can become a barrier to an organization’s success.</p>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>For operator managing multiple tasks can be difficult, especially when you have to
                        manage a large number of patients at a same time. At this moment there is lack of proper
                        guidence and communication regarding the reports of patients.</p>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Also whenever patients need to check their reports they have to visit react centre everytime.</p>
                </div>
            </div> */}

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>Scope of Work and Timeline</h3>
                    <p className='betfairSectionScopeParagraph'>All the activities involved in this project displayed over a 04 months timeline</p>

                    <div className='betfairSectionScope'>
                        <img src={scope1} className='ScopeIcons' alt='Ali' />
                        <img src={scope2} className='ScopeIcons' alt='Ali' />
                        <img src={scope3} className='ScopeIcons' alt='Ali' />
                        <img src={scope4} className='ScopeIcons' alt='Ali' />
                    </div>
                </div>
            </div>

            <div className='neuroMobileSectionInsights'>

                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>Design Principles</h3>
                    <p className='betfairSectionScopeParagraph'>Defining the top 5-6 design principles for the Bagation platform</p>
                </div>

                <div className='neuroMobileSectionInsightsMainContainer'>
                    <div className='rybaSectionInsightsDivsContainer'>
                        <div className='rybaSectiondesignPrincipalsdiv'>
                            <div className='bagationSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Simple </p>
                            </div>
                            <div className='bagationSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Human </p>
                            </div>


                            <div className='bagationSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Activates Gratitude </p>
                            </div>
                        </div>
                        <div className='rybaSectiondesignPrincipalsdiv'>
                            <div className='bagationSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Social </p>
                            </div>


                            <div className='bagationSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Instant </p>
                            </div>
                            <div className='bagationSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Accessible </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



            {/* <div className='neuroMobileSectionResearch'>
                <div className='rybaSectionScopeMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Scope of Work & Timeline</h3>
                    <p className='neuroMobileSectionConceptualizationParagraph'>We defined all the scope of work and
                        decided all the timelines before the project started there may be a little bit change of timeline
                        on the basis of priority work. All the activities involved in this project displayed over a 04
                        months timeline.</p>
                    <img src={scopePic} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div> */}
            

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>Bagation’s Style Guide</h3>
                    <p className='betfairSectionScopeParagraph'>Defining a basic foundations of brand identify for the Bagation</p>
                    <br></br>
                    <div className='valiantSectionHomeImgMain'>
                        <img src={styleGuide} className='researchPicNeuroMobile' alt='React Logo' />
                        
                    </div>
                </div>
            </div>

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>High-Fidelity Designs</h3>
                    <p className='betfairSectionScopeParagraph'>Website mockups and prototype designed for the users</p>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>On-Boarding to Bagation</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>We have designed a straightforward and streamlined onboarding process for users, allowing them to use Bagation with ease and efficiency, without the need for a lengthy and time-consuming onboarding process.</p>
                        </div>
                        {/* <img src={s1} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video1} autoPlay={"autoplay"} loop controls="controls" />
                    </div>
                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        {/* <img src={s2} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video2} autoPlay={"autoplay"} loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Search for Suitable Space</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>The user is given the freedom to search for available storage spaces based on their location and specific requirements, such as the desired area and the number of bags to be stored. This comprehensive search feature is designed to provide the user with the best possible storage options.</p>
                        </div>
                    </div>
                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Book shortlisted Space</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>After the user has selected a storage space from their shortlist, they will proceed to the booking process, which is both straightforward and user-friendly. To reserve the chosen storage space, the user simply needs to make the specified payment.</p>
                        </div>
                        {/* <img src={s3} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video3} autoPlay={"autoplay"} loop controls="controls" />
                    </div>
                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        {/* <img src={s3} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video4} autoPlay={"autoplay"} loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Add Space to Platform </h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>On the flip side, we also have a second user behaviour which we refer to as "Space Owners." These individuals can add their available space to our platform for the purpose of storage, which allows travellers to book the space. The process of listing a property on our platform is both straightforward and efficient. All that is required is to provide the storage details such as the location, size, and pricing information.</p>
                        </div>
                    </div>

                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h3 className='betfairSectionScopeMainHeading'>Conclusion</h3>
                    <p className='betfairSectionScopeParagraph'>Summarizing the experience, learnings and future plan</p>
                    

                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <img src={key} className='racvSectionTheSolutionVideo1' alt='React Logo' />
                        <div className='neuroMobileSectionTheSolutionSubDiv1'>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>To design an effective UX for a storage booking company, focus on understanding users' needs, keep the design simple, consistent, and mobile-friendly, prioritise security, optimise for search engines, and test and iterate based on user feedback.</p>
                        </div>
                    </div>

                </div>
            </div>
            {/* <br></br> */}

            {/* <div className='betfairSectionOverviewMain'>
                <div id="apartFromWork" className='mainClassApartFromWork'>
                    <h3 className='apartFromWorkText1' >Want to explore more, here are options</h3>
                    <br></br>
                    <div className='apartFromWorkPicsContainer' >
                        <img src={book} className='apartFromWorkPics' alt='Ali' />
                        <div className='latestWorkEmptyContainer'></div>
                        <div className='latestWorkEmptyHorizontalContainer'></div>
                        <img src={pod} className='apartFromWorkPics' alt='Ali' />
                    </div>

                </div>
            </div> */}

            {/* <div className='neuroMobileSpacer'></div> */}
            {/* <SectionFooter /> */}
        </div>
    )
}
