import React from 'react';
import Navbar from './../navbar/';
import cover from './cover.jpg';
import uniCover2 from './uniCover2.jpg';
import quizCover1 from './quizCover1.jpg';
import './homeLandingPage.modules.css';
import SectionHero from '../sectionHero/sectionHero';
import SectionApartFromWork from '../sectionApartFromWork/sectionApartFromWork';
import SectionLatestWork from '../sectionLatestWork/sectionLatestWork';
import SectionAbout from '../sectionAbout/sectionAbout';
import SectionEducation from '../sectionEducation/sectionEducation';
import SectionFooter from '../sectionFooter/sectionFooter';
import SectionTopBar from '../sectionTopBar/sectionTopBar';

export default () => {
    return (
        <div className='mainClassWebsite'>
            {/* <Navbar /> */}
            <div className='mainClassWebsiteHero'>
                <SectionTopBar page="home" />
                <div className='hero-nav-container'>
                    {/* <Navbar /> */}
                    <SectionHero />
                </div>
            </div>
            {/* <img src={cover} className='coverImage' alt='Ali' />
            <SectionAbout/>
            <img src={uniCover2} className='coverImage' alt='Ali in Uni' />
            <SectionEducation/>
            <img src={quizCover1} className='coverImageQuiz' alt='Ali at Quiz Competition' /> */}
            <SectionLatestWork />
            {/* <SectionApartFromWork /> */}
            {/* <SectionFooter /> */}

        </div>
    )
}