import React from 'react';
import './neuroTab.modules.css';
import threeMobiles from './images/threeMobiles.png';
import reactPic from './images/reactPic.PNG';
import researchPic from './images/researchPic.png';
import researchPic2 from './images/researchPic2.png';
import competitiorPic from './images/competitiorPic.png';
import resultsPic from './images/resultsPic.svg';
import sharingPic from './images/bluetooth.svg';
import securityPic from './images/exam.svg';
import personPic from './images/person.svg';
import terminatePic from './images/terminate.svg';
import uploadPic from './images/upload.svg';
import scopePic from './images/scopePic.png';
import conceptualizationPic from './images/conceptualization.png';
import moodPic from './images/mood.png';
import featurePic from './images/feature.png';
import brandPic from './images/brand.png';
import twoMobiles from './../sectionLatestWork/images/twoMobiles.png';
import laptop from './../sectionLatestWork/images/laptop.png';
import tabPic1 from './../sectionLatestWork/images/tabPic1.png';
import leftAero from './images/leftAero.png';
import vrImage from './images/vrImage.png';
// import video1 from "./videos/connection.mp4";
// import grant from "./videos/grant.mp4";
// import learn from "./videos/learn.mp4";
// import results from "./videos/results.mp4";
import video1 from "./videos/connection.mov";
import grant from "./videos/grant.mov";
import learn from "./videos/learn.mov";
import results from "./videos/results.mov";
import SectionTopBar from '../sectionTopBar/sectionTopBar';
import SectionFooter from '../sectionFooter/sectionFooter';

export default () => {
    return (
        <div className='mainClassNeuroMobileWebsite'>
            <SectionTopBar styleColor="#F1F0FC" />
            <div className='backAeroContainer'>
                <a className='backAeroContainerSub' href="javascript:history.back()"><img src={leftAero} className='leftAero' alt='Ali' /></a>
            </div>

            <div className='mainNeuroMobileContainer'>
                <h3 className='neuroMobileAppHeading'>React Neuro - Tablet App</h3>
                <p className='neuroTabAppParagraph'>A tablet app through which you an get examine your brain health.</p>

                <img src={threeMobiles} className='threeMobiles' alt='Ali' />

            </div>

            <div className='neuroMobileSectionOverview'>
                <div className='neuroMobileSectionOverviewDivOne'>
                    <h4 className='neuroMobileSectionOverviewHeading'>OVERVIEW</h4>
                    <br></br>
                    <p className='neuroMobileSectionOverviewParagraphs'><span className='neuroMobileSectionOverviewDivOneReact'>React Neuro</span> is a digital platform designed to change the way we engage with our brain health.
                        Building on decades of breakthrough research, we use Virtual Reality to probe deeply into the
                        neural circuits that make us tick.</p>

                    <p className='neuroMobileSectionOverviewParagraphs'>The aim of this project was to <span className='neuroMobileSectionUsersProfilesParagraphsBold' >solve the problem to get updates
                        regarding reports, trends and learnings</span> for React’s patients
                        through an app.</p>
                    <br></br>
                    <h5 className='neuroMobileSectionOverviewHeading'>CLIENT</h5>
                    <img src={reactPic} className='reactLogoNeuroMobile' alt='React Logo' />

                </div>
                <div className='neuroMobileSectionOverviewDivTwo'>
                    <h4 className='neuroMobileSectionOverviewHeading'>ROLE & DURATION</h4>
                    <br></br>
                    <h5 className='neuroMobileSectionUsersProfilesParagraphCustom' >UX Designer | VentureDive</h5>
                    <p className='neuroMobileSectionOverviewCustomParagraph'>User Research, Visual Design, Interaction, Prototyping.</p>
                    <p className='neuroMobileSectionOverviewParagraphs'>Jan 2022 - Mar 2022</p>
                </div>
            </div>

            <div className='neuroMobileSectionTheProblem'>
                <div className='neuroMobileSectionTheProblemMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>The Problem</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Poor communication between the operator and patient can become a barrier to an organization’s success.</p>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>For operator managing multiple tasks can be difficult, especially when you have to
                        manage a large number of patients at a same time. At this moment there is lack of proper
                        guidence and communication regarding the reports of patients.</p>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Also whenever patients need to check their reports they have to visit react centre everytime.</p>
                </div>
            </div>

            <div className='neuroMobileSectionResearch'>
                <div className='rybaSectionScopeMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Scope of Work & Timeline</h3>
                    <p className='neuroMobileSectionConceptualizationParagraph'>We defined all the scope of work and decided all the timelines before the project started there may be a
                        little bit change of timeline on the basis of priority work. All the activities involved in this
                        project displayed over a 03 months timeline.</p>
                    <img src={scopePic} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div>

            <div className='neuroMobileSectionResearch'>
                <div className='neuroMobileSectionResearchMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Research</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Conducted several meetings with React Neuro stakeholders to get better understanding of
                        the product, user needs and business requirements.</p>
                    <ul className='neuroMobileSectionConceptualizationMainParagraph'>
                        <li>Understanding the React Neuro’s business.</li>
                        <li>Day to day tasks of the user.</li>
                        <li>On which criteria reports are generated.</li>
                        <li>Understanding of graphical representation of results.</li>
                        <li>Understaning of the exams, domains and metrics of React Neuro.</li>
                    </ul>
                    <img src={researchPic} className='researchPicNeuroMobile' alt='React Logo' />
                    <img src={researchPic2} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div>

            <div className='neuroMobileSectionResearch'>
                <div className='neuroMobileSectionResearchMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Competitor Analysis</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Competitor Analysis helps us to get know more about the React’s product and
                        also their competitiors. It helps us in
                        building a good user experience.</p>
                    <img src={competitiorPic} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div>

            <div className='neuroMobileSectionInsights'>

                <div className='neuroMobileSectionInsightsMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Insights</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>After conducting countless meetings with stakeholders, contextual inquiry and analyzing the gathered data,
                        I was able to categorize the
                        insights into these <b>3 categories</b>.</p>
                </div>
                <div className='neuroMobileSectionInsightsMainContainer'>
                    <div className='neuroMobileSectionInsightsDivsContainer'>
                        <div className='neuroMobileSectionInsightsSingleDiv'>
                            <img src={resultsPic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='purpleHeadingNeuroMobile'>View Results</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs'>Now React Operators easily view and manage
                                the results of the patients in a simpler and easy view. Also they can get results of learnings of the
                                patients. Added a filter to search easily.</p>
                        </div>
                        <div className='neuroMobileSectionInsightsSingleDiv'>
                            <img src={sharingPic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='purpleHeadingNeuroMobile'>Build Bluetooth Connection</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs'>There was a issue in the previous bluetooth connection building
                                no itimation of the successful connection. Now everytime you are going to execute exam it
                                shows you a intimation of connection.</p>
                        </div>
                        <div className='neuroMobileSectionInsightsSingleDiv'>
                            <img src={securityPic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='purpleHeadingNeuroMobile'>Execute Exam</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs'>Now you can easily execute exam with seamless experience.
                                Now exam contains different modueles like trainings, collections, games and videos. Also
                                you can arrange them by dragging.</p>
                        </div>

                    </div>
                </div>
                <div className='neuroMobileSectionInsightsDivsContainer'>
                    <div className='neuroMobileSectionInsightsDivsContainerNew'>
                        <div className='neuroMobileSectionInsightsSingleDiv'>
                            <img src={terminatePic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='purpleHeadingNeuroMobile'>Terminate Exam</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs'>In the first iteration of the app there is not an option
                                to terminate exam and skip one module of the exam. Now operator can easily terminate exam any time and also
                                skip any of the module of the exam.</p>
                        </div>
                        <div className='neuroMobileSectionInsightsSingleDiv'>
                            <img src={uploadPic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='purpleHeadingNeuroMobile'>Upload Results</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs'>Now you can easily execute exam with seamless experience.
                                Now exam contains different modueles like trainings, collections, games and videos. Also you can
                                arrange them by dragging.</p>
                        </div>

                    </div>
                </div>

            </div>

            <div className='neuroMobileSectionUserProfiles'>
                <div className='neuroMobileSectionResearchMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>User Profiles</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>The research made it evident how different user would use the app diferently.
                        To cater to this I catogerized them into two user profiles based on their tasks and goals..</p>
                    <div >
                        <div className='neuroMobileSectionUserProfilesDivsContainerSpec'>
                            <img src={personPic} className='resultsPicNeuroMobile' alt='React Logo' />
                            <h3 className='purpleHeadingNeuroMobile'>React Operators</h3>
                            <p className='neuroMobileSectionUsersProfilesParagraphs'>
                                React Members (Patients) are mostly of senior citizens above the age of 50 years. They are not much tech savvy and don’t know much how to use technology and digital platforms.
                            </p>
                            <h5 className='neuroMobileSectionUsersProfilesHeadings'>Task Performed: </h5>
                            <ul className='neuroMobileSectionUsersProfilesParagraphs'>
                                <li>Check their medical reports and brain health score.</li>
                                <li>Maintain patient's data.</li>
                                <li> Execute exams for the specific patient according to their condition.</li>
                                <li>Track the learnings of the patients.</li>
                                <li>Responsible for the bluetooth connection with VR headset.</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <div className='neuroMobileSectionResearch'>
                <div className='neuroMobileSectionResearchMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Conceptualization</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>I started creating the user flows, branding, moodboarding and low-fi concepts for primary use cases.
                        After having a go-ahead from the Product Manager, Developers, and Stakeholders on the mockups
                        we began digitalizing designs.</p>
                    <br></br>
                    <div className='neuroMobileSectionConceptualizationSubDiv'>
                        <h4 className='neuroMobileSectionConceptualizationHeading'>Business Model Canvas</h4>
                        <p className='neuroMobileSectionConceptualizationParagraphTab'>After understanding the product’s requirements, the team
                            collaborated with the experts and discussed the business model for the platform</p>
                    </div>
                    <img src={conceptualizationPic} className='researchPicNeuroMobile' alt='React Logo' />
                    <br></br><br></br><br></br><br></br>
                    <div className='neuroMobileSectionConceptualizationSubDiv'>
                        <h4 className='neuroMobileSectionConceptualizationHeading'>Feature Defining</h4>
                        <p className='neuroMobileSectionConceptualizationParagraph'>Feature defining is done after all research to finalise the features.</p>
                    </div>
                    <img src={featurePic} className='researchPicNeuroMobile' alt='React Logo' />
                    <br></br><br></br>
                    <div className='neuroMobileSectionConceptualizationSubDiv'>
                        <h4 className='neuroMobileSectionConceptualizationHeading'>Moodboarding</h4>
                        <p className='neuroMobileSectionConceptualizationParagraph'>Moodboarding is done after the research and we geather all the visual inspirations here.</p>
                    </div>
                    <img src={moodPic} className='researchPicNeuroMobile' alt='React Logo' />
                    <br></br><br></br><br></br><br></br>
                    <div className='neuroMobileSectionConceptualizationSubDiv'>
                        <h4 className='neuroMobileSectionConceptualizationHeading'>Branding</h4>
                        <p className='neuroMobileSectionConceptualizationParagraph'>Defining a basic brand identify for the React product.</p>
                    </div>
                    <img src={brandPic} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div>

            <div className='neuroMobileSectionUserProfiles'>
                <div className='neuroMobileSectionResearchMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>The Solution</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>React Neuro’s mobile app makes
                        it easy to keep up-to-date and check their medical reports – wherever they are – using a
                        simple-to-use chat interface. Efficiently manage connections and also able to check connections
                        results too. They can manage their connections easily.</p>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <video className='neuroMobileSectionTheSolutionVideo' src={results} autoPlay={"autoplay"} preLoad="auto" loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Build Bluetooth Connection</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>While running exam, you have to
                                chceck whether the VR device is connected or not if not you have to perform the same actions as in
                                video. In the previous one it shows the message when eveything is ready that cause the bad
                                experience of the people.</p>
                        </div>
                    </div>

                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <video className='neuroMobileSectionTheSolutionVideo' src={video1} autoPlay={"autoplay"} preLoad="auto" loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Execute Modules</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>Now you can easily and smoothly
                                run different modules for better experience of the users. It also includes videos and games at
                                the end of exam to make user happy at the end.</p>
                        </div>
                    </div>

                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <video className='neuroMobileSectionTheSolutionVideo' src={grant} autoPlay={"autoplay"} preLoad="auto" loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Upload Results</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>You can easily view any report of any time through tablet app
                                by searching exam name or all the reports are arranged there in asecnding
                                order. Also you can now check results of learnings and trainings too. Also you have
                                filter option to make search more easier.</p>
                        </div>
                    </div>

                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <video className='neuroMobileSectionTheSolutionVideo' src={learn} autoPlay={"autoplay"} preLoad="auto" loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>View Results</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>You can easily view any
                                report of any time through tablet app by searching exam name or all the reports are
                                arranged there in asecnding order. Also you can now check results of learnings and
                                trainings too. Also you have filter option to make search more easier.</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className='neuroMobileSectionResearch'>
                <div className='neuroMobileSectionResearchMainDiv'>
                    <div className='neuroMobileSectionConceptualizationSubDiv'>
                        <h3 className='neuroMobileSectionResearchMainDivHeading'>VR UI Design</h3>
                        <p className='neuroMobileSectionConceptualizationParagraphMaxWidth'>I got an opportunity to design some
                            screens for the VR headset. It was my first experience to design for VR. It helped me alot
                            in polishing my skills and also I learned a new design skill.</p>
                    </div>
                    <img src={vrImage} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div>

            <div className='neuroMobileSectionUserProfiles'>
                <div className='neuroMobileSectionProjectLearningMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Project Learning</h3>
                    <br></br>
                    <h4 className='neuroMobileSectionProjectLearningHeadings'>1. Simplicity is strength</h4>
                    <p className='neuroMobileSectionProjectLearningMainParagraph'>As a designer, we are often lured
                        by attractive, trendy and out of the box designs. But, We must always remember the ‘why’. The
                        primary goal is to understand the user, their problems and then come up with a design that
                        solves it.</p>

                    <h4 className='neuroMobileSectionProjectLearningHeadings'>2. Prioritize</h4>
                    <p className='neuroMobileSectionProjectLearningMainParagraph'>
                        Create a strategic plan to launch an MVP. This helps deal with out-of-scope requests that
                        could potentially derail the project and helps deliver a quality product in time.</p>

                    <h4 className='neuroMobileSectionProjectLearningHeadings'>3. Seek out feedback early and continually</h4>
                    <p className='neuroMobileSectionProjectLearningMainParagraph'>
                        The trouble with most of us is that we would rather be ruined by praise than saved by criticism. Keeping the stakeholders/users in loop and testing solutions in whatever form (paper, low-fi or hi-fi) as early as possible saves ample amount of time and re-work.</p>
                </div>
            </div>

            <div className='neuroMobileSectionUserProfiles'>
                <div className='neuroMobileSectionInsightsMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>More Projects</h3>
                </div>
                <div className='neuroMobileSectionInsightsDivsContainer'>
                    <div>
                        <a href="/neighbourhood-mobile">
                            <div className='reactNeuroMobilesPicContainer'>
                                <img src={twoMobiles} className='Pic2NeuroMobile' alt='Ali' />
                            </div>
                        </a>
                        <h4 className='neuroMobileSectionMoreProjectsHeading'>Neighbourhood - Mobile App</h4>

                    </div>
                    <div>
                        <a href="/neuro-tab">
                            <div className='reactNeuroPicContainer'>
                                <img src={tabPic1} className='mobilePic1NeuroMobile' alt='Ali' />
                            </div>
                        </a>
                        <h4 className='neuroMobileSectionMoreProjectsHeading'>React Neuro - Tablet App</h4>
                    </div>
                    <div>
                        <a href="/project-ryba">
                            <div className='reactNeuroLaptopPicContainer'>
                                <img src={laptop} className='laptopPic1NeuroMobile' alt='Ali' />
                            </div>
                        </a>
                        <h4 className='neuroMobileSectionMoreProjectsHeading'>Project Ryba - Website</h4>
                    </div>
                </div>
            </div>
            <div className='neuroMobileSpacer'></div>
            {/* <SectionFooter /> */}
        </div>
    )
}
