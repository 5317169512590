import React from 'react';
import './betfair.modules.css';
import bg1 from './images/bg1.png';
import bg2 from './images/bg2.png';
import betfairLogo from './images/betfairLogo.png';
import threeButtons from './images/threeButtons.svg';
import overview from './images/overview.svg';
import problem from './images/problem.svg';
import solution from './images/solution.svg';
import scopeSVG from './images/scope.svg';
import scope1 from './images/scope1.svg';
import scope2 from './images/scope2.svg';
import scope3 from './images/scope3.svg';
import scope4 from './images/scope4.svg';
import video1 from "./videos/1.mov";
import video2 from "./videos/2.mov";
import video3 from "./videos/3.mov";
import video4 from "./videos/4.mov";
import LongTermGoal from './images/ltGoal.svg';
import ltg1 from './images/ltg1.png';
import design1 from './images/design1.png';
import design2 from './images/design2.png';
import s1 from './images/s1.png';
import s2 from './images/s2.png';
import s3 from './images/s3.png';
import s4 from './images/s4.png';
import key from './images/key.svg';
import book from '../sectionApartFromWork/images/book.svg';
import pod from '../sectionApartFromWork/images/pod.svg';

import reactPic from './images/reactPic.PNG';
import researchPic from './images/researchPic.png';
import competitiorPic from './images/competitiorPic.png';
import resultsPic from './images/resultsPic.svg';
import sharingPic from './images/sharingPic.svg';
import securityPic from './images/securityPic.svg';
import personPic from './images/person.svg';
import scopePic from './images/scopePic.png';
import conceptualizationPic from './images/conceptualization.png';
import moodPic from './images/mood.png';
import brandPic from './images/brand.png';
import twoMobiles from './../sectionLatestWork/images/twoMobiles.png';
import laptop from './../sectionLatestWork/images/laptop.png';
import tabPic1 from './../sectionLatestWork/images/tabPic1.png';
import leftAero from './images/leftAero.svg';
import SectionTopBar from '../sectionTopBar/sectionTopBar';
import SectionFooter from '../sectionFooter/sectionFooter';

export default () => {
    return (
        <div className='mainClassNeuroMobileWebsite'>
            <SectionTopBar styleColor="#FFFFFF" />
            {/* <div className='backAeroContainer'>
                <a className='backAeroContainerSub' href="javascript:history.back()"><img src={leftAero} className='leftAero' alt='Ali' /></a>
            </div> */}

            {/* <div className='mainNeuroMobileContainer'>
                <h3 className='neuroMobileAppHeading'>betfair</h3>
                <p className='neuroMobileAppParagraph'>A single app to get update about your and yours loved one’s mental health.</p>

                <img src={threeMobiles} className='threeMobiles' alt='Ali' />

            </div> */}

            <div className='mainClassBetfairHero'>
                <div className='subClassBetfairHero'>
                    <div className='leftClassBetfairHero'>

                        <div className='innerleftClassBetfairHero'>

                            <div className='backAeroContainerBetfairHero'>
                                <a className='backAeroContainerBetfairHeroSub' href="javascript:history.back()"><img src={leftAero} className='leftAero' alt='Ali' /></a>
                            </div>

                            <img src={betfairLogo} className='LogoBetfair' alt='Ali' />

                            <h1 className='betfairHeroMainHeading'>All for betting in one place and Australia’s only betting exchange!</h1>

                            <h1 className='betfairHeroSubHeading'>You can learn about how to bet from Betfair Hub - education section of betfair</h1>
                            <img src={threeButtons} className='threeButtons' alt='Ali' />
                        </div>

                    </div>

                    <div className='heroMainImageBackgroundBetfair'>
                        <img src={bg2} className='imageHeroBetfair' alt='Ali' />
                    </div>
                </div>

            </div>

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionOverview'>
                    <div className='betfairSectionOverviewDivOne'>
                        <h4 className='betfairSectionOverviewHeading'>ROLE</h4>
                        {/* <br></br> */}
                        <p className='betfairSectionOverviewCustomParagraph'>Senior UX/UI Designer</p>

                    </div>
                    <div className='betfairSectionOverviewDivOne'>
                        <h4 className='betfairSectionOverviewHeading'>RESPONSIBLITY</h4>
                        {/* <br></br> */}
                        <p className='betfairSectionOverviewCustomParagraph'>End-to-End Design Process</p>
                    </div>
                    <div className='betfairSectionOverviewDivOne'>
                        <h4 className='betfairSectionOverviewHeading'>TEAM</h4>
                        {/* <br></br> */}
                        <p className='betfairSectionOverviewCustomParagraph'>X-functional team of six Solo Designer</p>
                    </div>
                </div>
            </div>

            {/*Section Overview Problem Solution  */}
            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <div className='betfairSectionProblemChallangeDivOne'>
                        <img src={overview} className='ProblemChallangeIcons' alt='Ali' />
                        <p className='betfairSectionProblemChallangeParagraph'>Betfair Australia is the Australian operation of the web betting exchange, Betfair. Established in 2005, Betfair Australia operates Australia's only betting exchange under a Tasmanian Gaming Licence. Since August 2014 Betfair Australia has been fully owned by Crown Resorts.</p>
                    </div>
                    <div className='betfairSectionProblemChallangeDivOne'>
                        <img src={problem} className='ProblemChallangeIcons' alt='Ali' />
                        <p className='betfairSectionProblemChallangeParagraph'>Many of the active users of Betfair don’t know much about the betting and how to lay/back on bet. They also are not aware of the categories of the Betfair on where they can bet and which bet will be more beneficial to them.</p>
                    </div>
                    <div className='betfairSectionProblemChallangeDivOne'>
                        <img src={solution} className='ProblemChallangeIcons' alt='Ali' />
                        <p className='betfairSectionProblemChallangeParagraph'>We introduce a platform with the name of <b>“Betfair Hub”</b> where they can find all the articles (Expert Tips) and writings from different authors which will help them to learn how they can bet more efficiently and in a way which will be beneficial for them. We have different categories of sports where they can navigate easily between them. </p>
                    </div>
                </div>
            </div>


            {/* <div className='neuroMobileSectionOverview'>
                <div className='neuroMobileSectionOverviewDivOne'>
                    <h4 className='neuroMobileSectionOverviewHeading'>OVERVIEW</h4>
                    <br></br>
                    <p className='neuroMobileSectionOverviewParagraphs'><span className='neuroMobileSectionOverviewDivOneReact'>React Neuro</span> is a digital platform designed to change the way we engage with our brain health.
                        Building on decades of breakthrough research, we use Virtual Reality to probe deeply into the
                        neural circuits that make us tick.</p>

                    <p className='neuroMobileSectionOverviewParagraphs'>The aim of this project was to <span className='neuroMobileSectionUsersProfilesParagraphsBold' >solve the problem to get updates
                        regarding reports, trends and learnings</span> for React’s patients
                        through an app.</p>
                    <br></br>
                    <h5 className='neuroMobileSectionOverviewHeading'>CLIENT</h5>
                    <img src={reactPic} className='reactLogoNeuroMobile' alt='React Logo' />

                </div>
                <div className='neuroMobileSectionOverviewDivTwo'>
                    <h4 className='neuroMobileSectionOverviewHeading'>ROLE & DURATION</h4>
                    <br></br>
                    <h5 className='neuroMobileSectionUsersProfilesParagraphCustom' >UX Designer | VentureDive</h5>
                    <p className='neuroMobileSectionOverviewCustomParagraph'>User Research, Visual Design, Interaction, Prototyping.</p>
                    <p className='neuroMobileSectionOverviewParagraphs'>Nov 2021 - Feb 2022</p>
                </div>
            </div> */}

            {/* <div className='neuroMobileSectionTheProblem'>
                <div className='neuroMobileSectionTheProblemMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>The Problem</h3>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Poor communication between the operator and patient can become a barrier to an organization’s success.</p>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>For operator managing multiple tasks can be difficult, especially when you have to
                        manage a large number of patients at a same time. At this moment there is lack of proper
                        guidence and communication regarding the reports of patients.</p>
                    <p className='neuroMobileSectionConceptualizationMainParagraph'>Also whenever patients need to check their reports they have to visit react centre everytime.</p>
                </div>
            </div> */}

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>Scope of Work and Timeline</h3>
                    <p className='betfairSectionScopeParagraph'>All the activities involved in this project displayed over a 04 months timeline.</p>

                    <div className='betfairSectionScope'>
                        <img src={scope1} className='ScopeIcons' alt='Ali' />
                        <img src={scope2} className='ScopeIcons' alt='Ali' />
                        <img src={scope3} className='ScopeIcons' alt='Ali' />
                        <img src={scope4} className='ScopeIcons' alt='Ali' />
                    </div>

                    {/* <img src={scopeSVG} className='researchPicNeuroMobile' alt='React Logo' /> */}
                </div>
            </div>

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>Long Term Goal</h3>
                    <p className='betfairSectionScopeParagraph'>Defining the long term goal i-e the optimistic view of the world where our product is successful.</p>

                    <img src={LongTermGoal} className='betfairLongTermPic' alt='React Logo' />
                    <img src={ltg1} className='betfairLongTermPic1' alt='React Logo' />
                </div>
            </div>

            {/* <div className='neuroMobileSectionResearch'>
                <div className='rybaSectionScopeMainDiv'>
                    <h3 className='neuroMobileSectionResearchMainDivHeading'>Scope of Work & Timeline</h3>
                    <p className='neuroMobileSectionConceptualizationParagraph'>We defined all the scope of work and
                        decided all the timelines before the project started there may be a little bit change of timeline
                        on the basis of priority work. All the activities involved in this project displayed over a 04
                        months timeline.</p>
                    <img src={scopePic} className='researchPicNeuroMobile' alt='React Logo' />
                </div>
            </div> */}

            <div className='neuroMobileSectionInsights'>

                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>Design Principles</h3>
                    <p className='betfairSectionScopeParagraph'>Defining the top 5-6 design principles for the Betfair platform</p>
                </div>
                <div className='neuroMobileSectionInsightsMainContainer'>
                    <div className='rybaSectionInsightsDivsContainer'>
                        <div className='rybaSectiondesignPrincipalsdiv'>
                            <div className='rybaSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Simple </p>
                            </div>
                            <div className='rybaSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Human </p>
                            </div>


                            <div className='rybaSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Activates Gratitude </p>
                            </div>
                        </div>
                        <div className='rybaSectiondesignPrincipalsdiv'>
                            <div className='rybaSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Social </p>
                            </div>


                            <div className='rybaSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Instant </p>
                            </div>
                            <div className='rybaSectionInsightsSingleDiv'>
                                <p className='neuroMobileSectionUsersProfilesParagraphs'>Accessible </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>Betfair’s Design System</h3>
                    <p className='betfairSectionScopeParagraph'>Defining a basic foundations of brand identify for the Betfair</p>
                    <br></br>
                    <div className='rybaSectionHomeImgMain'>
                        <img src={design1} className='rybaSectionHomeImg1' alt='React Logo' />
                        <br></br>
                        <img src={design2} className='rybaSectionHomeMobileImg1' alt='React Logo' />
                    </div>
                </div>
            </div>

            <div className='betfairSectionOverviewMain'>
                <div className='betfairSectionProblemChallange'>
                    <h3 className='betfairSectionScopeMainHeading'>High-Fidelity Designs</h3>
                    <p className='betfairSectionScopeParagraph'>Website mockups and prototype designed for the users</p>
                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Space for Betfair Hub</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>We have a separate platform for hub where users have penalty of options for learning and they have liberty to navigate between different categories and they can have different expert tips to learn more about betting.</p>
                        </div>
                        {/* <img src={s1} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video1} autoPlay={"autoplay"} loop controls="controls" />
                    </div>
                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        {/* <img src={s2} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video2} autoPlay={"autoplay"} loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Way of Learning - Expert Tips</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>Expert tips are recommendations based on historical data and personal expertise, offered to assist users in making more informed betting decisions. While they can provide valuable insights, users should always do their own research and exercise their own judgment before placing a bet.</p>
                        </div>
                    </div>
                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Progressive Disclosure</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>We use progressive disclosure to reveal information to users gradually, as they need it, rather than overwhelming them with too much information at once. In the context of a betting site, this can mean displaying key information about a bet or event upfront, and then offering more detailed information as the user interacts with the site.</p>
                        </div>
                        {/* <img src={s3} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video3} autoPlay={"autoplay"} loop controls="controls" />
                    </div>
                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        {/* <img src={s4} className='neuroMobileSectionTheSolutionVideo' alt='React Logo' /> */}
                        <video className='neuroMobileSectionTheSolutionVideo' src={video4} autoPlay={"autoplay"} loop controls="controls" />
                        <div className='neuroMobileSectionTheSolutionSubDiv'>
                            <h3 className='neuroMobileSectionResearchMainDivHeading'>Predictor Models</h3>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>Predictor models for betting sites use historical data to predict future outcomes of sporting events or games, helping users make more informed betting decisions based on statistical analysis.</p>
                        </div>
                    </div>

                    <br></br>
                    <div className='neuroMobileSectionTheSolutionVideoAndParagraphDiv'>
                        <img src={key} className='neuroMobileSectionTheSolutionVideo1' alt='React Logo' />
                        <div className='neuroMobileSectionTheSolutionSubDiv1'>
                            <p className='neuroMobileSectionConceptualizationMainParagraph'>Working at Betfair was a great learning experience. We followed our pre-defined design process to create a solution backed by research and validation. We were able to gather interesting insights from the stakeholders and the end users which helped shape the solution for the better. I also had the opportunity to conduct testing sessions using Useberry, and it was truly an amazing experience to receive feedback from users regarding our designs.</p>
                        </div>
                    </div>

                </div>
            </div>
            {/* <br></br> */}

            {/* <div className='betfairSectionOverviewMain'>
                <div id="apartFromWork" className='mainClassApartFromWork'>
                    <h3 className='apartFromWorkText1' >Want to explore more, here are options</h3>
                    <br></br>
                    <div className='apartFromWorkPicsContainer' >
                        <img src={book} className='apartFromWorkPics' alt='Ali' />
                        <div className='latestWorkEmptyContainer'></div>
                        <div className='latestWorkEmptyHorizontalContainer'></div>
                        <img src={pod} className='apartFromWorkPics' alt='Ali' />
                    </div>

                </div>
            </div> */}

            {/* <div className='neuroMobileSpacer'></div> */}
            {/* <SectionFooter /> */}
        </div>
    )
}
